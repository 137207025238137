define("sentry-advisor/pods/components/site-history/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "utgm3rOB",
    "block": "{\"symbols\":[],\"statements\":[[8,\"paper-content\",[[24,1,\"site-history\"],[24,0,\"page-content\"]],[[\"@padding\"],[false]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,1],null,[[\"pumps\"],[[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"pumps\",\"charts/pump-starts-runtime-graph\"]}",
    "moduleName": "sentry-advisor/pods/components/site-history/template.hbs"
  });

  _exports.default = _default;
});