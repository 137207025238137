define('ember-paper-stepper/components/paper-stepper', ['exports', '@ember/string', 'ember-paper-stepper/templates/components/paper-stepper', 'ember-composability-tools'], function (exports, _string, _paperStepper, _emberComposabilityTools) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function computedFallbackIfUndefined(fallback) {
    return Ember.computed({
      get: function get() {
        return fallback;
      },
      set: function set(_, v) {
        return v === undefined ? fallback : v;
      }
    });
  }

  exports.default = Ember.Component.extend(_emberComposabilityTools.ParentMixin, {
    layout: _paperStepper.default,
    tagName: 'md-stepper',
    classNameBindings: ['vertical::layout-column'],

    stepComponent: 'paper-step',

    linear: computedFallbackIfUndefined(true),
    vertical: computedFallbackIfUndefined(false),

    // fallback to 0 if is undefined
    currentStep: computedFallbackIfUndefined(0),
    currentStepLabel: Ember.computed('currentStep', function () {
      return this.get('currentStep') + 1;
    }),
    totalSteps: Ember.computed.reads('steps.length'),

    steps: Ember.computed.sort('childComponents', function (a, b) {
      if (a.get('stepNumber') > b.get('stepNumber')) {
        return 1;
      } else if (a.get('stepNumber') < b.get('stepNumber')) {
        return -1;
      }
      return 0;
    }),

    mobileHeaderTemplate: 'Step %@ of %@',
    mobileHeaderLabel: Ember.computed('mobileHeaderTemplate', 'currentStepLabel', 'totalSteps', function () {
      var _getProperties = this.getProperties('mobileHeaderTemplate', 'currentStepLabel', 'totalSteps'),
          mobileHeaderTemplate = _getProperties.mobileHeaderTemplate,
          currentStepLabel = _getProperties.currentStepLabel,
          totalSteps = _getProperties.totalSteps;

      return (0, _string.loc)(mobileHeaderTemplate, [currentStepLabel, totalSteps]);
    }),

    goTo: function goTo(stepNumber) {
      if (stepNumber < this.get('totalSteps') && this.get('onStepChange')) {
        this.get('onStepChange')(stepNumber);
      }
    },
    nextStep: function nextStep() {
      if (this.get('currentStep') < this.get('totalSteps')) {
        var nextStep = this.get('currentStep') + 1;
        if (this.get('onStepChange')) {
          this.get('onStepChange')(nextStep);
        }
        if (nextStep === this.get('totalSteps') && this.get('onStepperCompleted')) {
          this.get('onStepperCompleted')();
        }
      }
    },
    previousStep: function previousStep() {
      if (this.get('currentStep') > 0 && this.get('onStepChange')) {
        this.get('onStepChange')(this.get('currentStep') - 1);
      }
    },
    registerChild: function registerChild(childComponent) {
      this._super.apply(this, arguments);
      // automatically set stepNumber if not manually set
      if (childComponent.get('stepNumber') === undefined) {
        var stepNumber = this.childComponents.get('length');
        childComponent.set('stepNumber', stepNumber - 1);
      }

      this.updateLabels();
    },
    unregisterChild: function unregisterChild() {
      this._super.apply(this, arguments);
      this.updateLabels();
    },
    updateLabels: function updateLabels() {
      // update labels based on the sorted index
      this.get('steps').forEach(function (c, i) {
        if (c.get('orderedIndex') !== i) {
          c.set('orderedIndex', i);
        }
      });
    }
  });
});