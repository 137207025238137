define("sentry-advisor/pods/components/right-side-bars/alarms-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Inlpo/T0",
    "block": "{\"symbols\":[\"alarm\"],\"statements\":[[8,\"paper-content\",[[24,0,\"flex-grow layout-column\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"alarm-list-item\",[],[[\"@alarm\",\"@compressed\",\"@showLocation\",\"@onClick\"],[[32,1],true,true,[30,[36,1],[[32,0],\"transitionToAlarmHome\",[32,1]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"  \"],[8,\"paper-item\",[],[[],[]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"no-item\"],[[\"item\"],[[30,[36,0],[\"active-alarm\"],null]]]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"alarms\",\"-track-array\",\"each\"]}",
    "moduleName": "sentry-advisor/pods/components/right-side-bars/alarms-list/template.hbs"
  });

  _exports.default = _default;
});