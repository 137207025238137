define("htt-shared-models/models/dashboard-element-reports/dashboard-element-report-models", ["exports", "htt-shared-models/models/dashboard-element-reports/analog-daily-statistic", "htt-shared-models/models/dashboard-element-reports/analog-data-dump", "htt-shared-models/models/dashboard-element-reports/analog-samples-report", "htt-shared-models/models/dashboard-element-reports/analog-usage-report", "htt-shared-models/models/dashboard-element-reports/ap-report", "htt-shared-models/models/dashboard-element-reports/counter-report", "htt-shared-models/models/dashboard-element-reports/di-report", "htt-shared-models/models/dashboard-element-reports/digital-output-report", "htt-shared-models/models/dashboard-element-reports/gas-odor-report", "htt-shared-models/models/dashboard-element-reports/multi-counter-report", "htt-shared-models/models/dashboard-element-reports/multi-zone-meter-report", "htt-shared-models/models/dashboard-element-reports/pump-report", "htt-shared-models/models/dashboard-element-reports/pump-runtime-report", "htt-shared-models/models/dashboard-element-reports/pump-starts-and-runtime-report", "htt-shared-models/models/dashboard-element-reports/pump-starts-report", "htt-shared-models/models/dashboard-element-reports/rain-counter-report", "htt-shared-models/models/dashboard-element-reports/valve-report"], function (_exports, _analogDailyStatistic, _analogDataDump, _analogSamplesReport, _analogUsageReport, _apReport, _counterReport, _diReport, _digitalOutputReport, _gasOdorReport, _multiCounterReport, _multiZoneMeterReport, _pumpReport, _pumpRuntimeReport, _pumpStartsAndRuntimeReport, _pumpStartsReport, _rainCounterReport, _valveReport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DashboardElementReportModels = [_analogDataDump.default, _analogDailyStatistic.default, _analogUsageReport.default, _apReport.default, _counterReport.default, _diReport.default, _digitalOutputReport.default, _pumpReport.default, _valveReport.default, _pumpStartsReport.default, _pumpRuntimeReport.default, _pumpStartsAndRuntimeReport.default, _gasOdorReport.default, _rainCounterReport.default, _multiCounterReport.default, _multiZoneMeterReport.default, _analogSamplesReport.default];
  var _default = DashboardElementReportModels;
  _exports.default = _default;
});