define("sentry-advisor/pods/components/service-logs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2spCWsLQ",
    "block": "{\"symbols\":[\"@showSite\",\"@site\"],\"statements\":[[8,\"query-manager-view\",[],[[\"@listView\",\"@onItemClick\",\"@queryManager\",\"@columns\",\"@filterComponent\",\"@listItemComponent\",\"@onCreate\"],[[32,0,[\"globals\",\"isSmall\"]],[32,0,[\"selectLog\"]],[32,0,[\"queryManager\"]],[32,0,[\"columns\"]],\"logs-filters\",\"ui/list-items/log\",[32,0,[\"create\"]]]],null],[2,\"\\n\"],[6,[37,0],[[32,0,[\"showModal\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"dialogs/service-log\",[],[[\"@showSite\",\"@serviceLog\",\"@site\",\"@onClose\",\"@afterDelete\",\"@onSave\"],[[32,1],[32,0,[\"selectedServiceLog\"]],[32,2],[32,0,[\"closeModal\"]],[32,0,[\"closeModalAndFetchData\"]],[32,0,[\"closeModalAndFetchData\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "sentry-advisor/pods/components/service-logs/template.hbs"
  });

  _exports.default = _default;
});