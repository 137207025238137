define("sentry-advisor/pods/components/dialogs/user/forms/alarm-sound/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tXTYoYgK",
    "block": "{\"symbols\":[\"opt\"],\"statements\":[[8,\"paper-dialog-toolbar\",[],[[\"@title\"],[[30,[36,0],[\"edit-item\"],[[\"item\"],[[30,[36,0],[\"alarm-sound\"],null]]]]]],null],[2,\"\\n\"],[8,\"paper-dialog-content\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"pending-block\",[],[[\"@pending\"],[[32,0,[\"pending\"]]]],null],[2,\"\\n  \"],[8,\"error-block\",[],[[\"@error\"],[[32,0,[\"error\"]]]],null],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"layout-column\"],[12],[2,\"\\n    \"],[8,\"paper-select\",[],[[\"@label\",\"@selected\",\"@isTouched\",\"@options\",\"@onChange\",\"@errors\"],[[30,[36,0],[\"alarm-sound\"],null],[30,[36,3],[\"value\",[35,2,[\"alarmSound\"]],[35,1]],null],[32,0,[\"isTouched\"]],[32,0,[\"options\"]],[30,[36,5],[[32,0],[30,[36,4],[[35,2,[\"alarmSound\"]]],null]],[[\"value\"],[\"value\"]]],[32,0,[\"changeset\",\"error\",\"alarmSound\",\"validation\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,1,[\"name\"]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"paper-dialog-actions\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"span\"],[14,0,\"flex\"],[12],[13],[2,\"\\n  \"],[8,\"paper-button\",[],[[\"@onClick\",\"@disabled\"],[[32,0,[\"onCancel\"]],[32,0,[\"pending\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"cancel\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"paper-button\",[],[[\"@primary\",\"@disabled\",\"@onClick\"],[true,[30,[36,6],[[32,0,[\"pending\"]],[32,0,[\"changeset\",\"isInvalid\"]]],null],[30,[36,5],[[32,0],[32,0,[\"onSave\"]],[35,2]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"save\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"options\",\"changeset\",\"find-by\",\"mut\",\"action\",\"or\"]}",
    "moduleName": "sentry-advisor/pods/components/dialogs/user/forms/alarm-sound/template.hbs"
  });

  _exports.default = _default;
});