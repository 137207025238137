define("sentry-advisor/pods/components/ui/cells/sentry-advisor-message-statuses/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aRpB4wvN",
    "block": "{\"symbols\":[\"flag\",\"name\",\"@value\"],\"statements\":[[10,\"md-chips-wrap\"],[14,0,\"md-chips\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,3]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"md-chip\"],[15,0,[31,[[30,[36,0],[[32,1],\"sa-on-bg-color\",\"sa-off-bg-color\"],null]]]],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"md-chip-content color-white\"],[12],[2,\"\\n        \"],[10,\"span\"],[12],[2,\"\\n          \"],[1,[32,2]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"-each-in\",\"each\"]}",
    "moduleName": "sentry-advisor/pods/components/ui/cells/sentry-advisor-message-statuses/template.hbs"
  });

  _exports.default = _default;
});