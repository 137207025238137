define("sentry-advisor/pods/components/dialogs/sentry-advisor-site-contract/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hXMP0zVh",
    "block": "{\"symbols\":[\"contractType\",\"@onClose\"],\"statements\":[[8,\"paper-dialog\",[],[[\"@class\",\"@fullscreen\"],[\"flex-gt-sm-50\",true]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[8,\"paper-dialog-toolbar\",[],[[\"@title\",\"@onClose\"],[[30,[36,0],[\"contract\"],null],[32,2]]],null],[2,\"\\n\\n    \"],[8,\"paper-dialog-content\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"paper-list\",[],[[\"@class\"],[\"padding-none\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"paper-select\",[],[[\"@label\",\"@options\",\"@selected\",\"@onChange\"],[\"Contract Type\",[32,0,[\"contractTypes\"]],[32,0,[\"contractType\"]],[32,0,[\"setContractType\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n              \"],[1,[32,1,[\"type\"]]],[2,\" - \"],[1,[32,1,[\"description\"]]],[2,\"\\n            \"]],\"parameters\":[1]}]]],[2,\"\\n            \"],[8,\"paper-item\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Renewal Date:\\n                \"],[8,\"ui/inputs/datepicker\",[],[[\"@date\",\"@onChange\"],[[32,0,[\"renewalDate\"]],[32,0,[\"updateRenewalDate\"]]]],null],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"paper-dialog-actions\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"span\"],[14,0,\"flex\"],[12],[13],[2,\"\\n        \"],[8,\"paper-button\",[],[[\"@onClick\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[\"cancel\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"paper-button\",[],[[\"@primary\",\"@disabled\",\"@onClick\"],[true,[32,0,[\"saving\"]],[32,0,[\"save\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[\"save\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "sentry-advisor/pods/components/dialogs/sentry-advisor-site-contract/template.hbs"
  });

  _exports.default = _default;
});