define("sentry-advisor/pods/sentry-advisor-models/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hND8zIWh",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"dialogs/sentry-advisor-model\",[],[[\"@sentryAdvisorModel\",\"@status\",\"@title\",\"@onCreate\",\"@onCancel\"],[[32,1],\"create\",[30,[36,0],[\"newItem\"],[[\"item\"],[[30,[36,0],[\"sentryAdvisorModel.one\"],null]]]],[32,0,[\"onCreate\"]],[30,[36,1],[\"sentry-advisor-models\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"t\",\"transition-to\"]}",
    "moduleName": "sentry-advisor/pods/sentry-advisor-models/new/template.hbs"
  });

  _exports.default = _default;
});