define("sentry-advisor/pods/components/reports/sentry-advisor-message-list-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "69wjRXwi",
    "block": "{\"symbols\":[],\"statements\":[[8,\"paper-button\",[],[[\"@primary\",\"@onClick\"],[true,[30,[36,0],[[32,0],[30,[36,1],[[32,0,[\"showSendReportForm\"]]],null],true],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"cloud_download\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[1,[30,[36,2],[\"download\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"showSendReportForm\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"reports/date-range-dialog\",[],[[\"@onSubmit\",\"@onCancel\",\"@allDisabled\"],[[30,[36,0],[[32,0],[32,0,[\"sendReport\"]]],null],[30,[36,0],[[32,0],[30,[36,1],[[32,0,[\"showSendReportForm\"]]],null],false],null],[32,0,[\"emailSent\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"mut\",\"t\",\"if\"]}",
    "moduleName": "sentry-advisor/pods/components/reports/sentry-advisor-message-list-button/template.hbs"
  });

  _exports.default = _default;
});