define("htt-ui/components/form-save-cancel-btns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="layout-row flex-none">
    <span class="flex-grow"></span>
    <PaperButton @raised={{false}} @accent={{true}} @onClick={{@onCancel}}>
      Cancel
    </PaperButton>
    <PaperButton
      @raised={{true}}
      @primary={{true}}
      @onClick={{@onSubmit}}
      @type="submit"
    >
      Save
    </PaperButton>
  </div>
  */
  {
    "id": "ukvUKund",
    "block": "{\"symbols\":[\"@onCancel\",\"@onSubmit\"],\"statements\":[[10,\"div\"],[14,0,\"layout-row flex-none\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"flex-grow\"],[12],[13],[2,\"\\n  \"],[8,\"paper-button\",[],[[\"@raised\",\"@accent\",\"@onClick\"],[false,true,[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Cancel\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"paper-button\",[],[[\"@raised\",\"@primary\",\"@onClick\",\"@type\"],[true,true,[32,2],\"submit\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    Save\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "htt-ui/components/form-save-cancel-btns.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});