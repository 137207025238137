define("sentry-advisor/pods/components/sentry-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P78lsT9k",
    "block": "{\"symbols\":[\"controls\",\"@onClick\",\"@item\"],\"statements\":[[8,\"paper-item\",[[24,0,\"md-3-line\"]],[[\"@onClick\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"paper-icon\",[[24,0,\"md-avatar-icon\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    developer_board\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"md-list-item-text\"],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[2,\"\\n      \"],[1,[32,3,[\"label\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[32,3,[\"sentryAdvisorModel\",\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[32,3,[\"owner\",\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "sentry-advisor/pods/components/sentry-item/template.hbs"
  });

  _exports.default = _default;
});