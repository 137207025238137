define("htt-ui/components/ui/list-items/log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PaperItem @onClick={{@onClick}} @class="md-3-line" data-test-log-item>
    <PaperIcon class="md-avatar-icon">
      {{if (eq @item.type "service-log") "construction" "support_agent"}}
    </PaperIcon>
    <div class="md-list-item-text">
      <h3>
        {{@item.action}}
      </h3>
      <h4>
        {{@item.installation.name}}
      </h4>
      <p>
        {{@item.formattedTime}}
      </p>
    </div>
  </PaperItem>
  */
  {
    "id": "0va/Qlxc",
    "block": "{\"symbols\":[\"@onClick\",\"@item\"],\"statements\":[[8,\"paper-item\",[[24,\"data-test-log-item\",\"\"]],[[\"@onClick\",\"@class\"],[[32,1],\"md-3-line\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"paper-icon\",[[24,0,\"md-avatar-icon\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],[[32,2,[\"type\"]],\"service-log\"],null],\"construction\",\"support_agent\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"md-list-item-text\"],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[2,\"\\n      \"],[1,[32,2,[\"action\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"\\n      \"],[1,[32,2,[\"installation\",\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[32,2,[\"formattedTime\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\"]}",
    "moduleName": "htt-ui/components/ui/list-items/log.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});