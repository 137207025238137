define("sentry-advisor/pods/account-group/accounts/account/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rwu2sGZo",
    "block": "{\"symbols\":[],\"statements\":[[8,\"dialogs/account\",[],[[\"@account\",\"@editing\",\"@title\",\"@onCancel\",\"@onClose\"],[[32,0,[\"model\"]],false,[32,0,[\"model\",\"label\"]],[30,[36,0],[\"account-group.accounts\"],null],[30,[36,0],[\"account-group.accounts\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"transition-to\"]}",
    "moduleName": "sentry-advisor/pods/account-group/accounts/account/template.hbs"
  });

  _exports.default = _default;
});