define("sentry-advisor/pods/account/sites-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "evuFHTDl",
    "block": "{\"symbols\":[\"tabs\"],\"statements\":[[8,\"app-bars/account\",[],[[\"@account\",\"@title\",\"@group\"],[[32,0,[\"account\"]],[32,0,[\"group\",\"name\"]],[32,0,[\"group\"]]]],null],[2,\"\\n\\n\"],[8,\"paper-tabs\",[],[[\"@selected\",\"@borderBottom\",\"@onChange\"],[[32,0,[\"router\",\"currentRouteName\"]],true,[32,0,[\"noop\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"tab\"]],[],[[\"@value\",\"@href\"],[\"account.sites-group.sites\",[32,0,[\"sitesUrl\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"sites\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,[32,1,[\"tab\"]],[],[[\"@value\",\"@href\"],[\"account.sites-group.map\",[32,0,[\"mapUrl\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"map\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\"]}",
    "moduleName": "sentry-advisor/pods/account/sites-group/template.hbs"
  });

  _exports.default = _default;
});