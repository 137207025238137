define("htt-ui/validations/alarm-group", ["exports", "ember-changeset-validations/validators", "htt-ui/validators/if-not", "htt-ui/validators/if-less-than"], function (_exports, _validators, _ifNot, _ifLessThan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: (0, _validators.validatePresence)(true),
    installations: [(0, _validators.validateLength)({
      max: 500,
      message: "This alarm group is full"
    })],
    startOutageWindowMinutes: [(0, _ifNot.validateIf)(["checkForOutages"], (0, _validators.validateNumber)({
      gte: 1,
      presence: true,
      allowString: true,
      allowBlank: false
    })), (0, _ifNot.validateIf)(["checkForOutages"], (0, _ifLessThan.validateLessThan)("clearOutageWindowMinutes"))],
    startOutageThreshold: [(0, _ifNot.validateIf)(["checkForOutages"], (0, _validators.validateNumber)({
      gte: 1,
      presence: true,
      allowString: true,
      allowBlank: false
    })), (0, _ifNot.validateIf)(["checkForOutages"], (0, _ifLessThan.validateGreaterThan)("clearOutageThreshold"))],
    clearOutageWindowMinutes: [(0, _ifNot.validateIf)(["checkForOutages"], (0, _validators.validateNumber)({
      gte: 1,
      presence: true,
      allowString: true,
      allowBlank: false
    })), (0, _ifNot.validateIf)(["checkForOutages"], (0, _ifLessThan.validateGreaterThan)("startOutageWindowMinutes"))],
    clearOutageThreshold: [(0, _ifNot.validateIf)(["checkForOutages"], (0, _validators.validateNumber)({
      gte: 0,
      presence: true,
      allowString: true,
      allowBlank: false
    })), (0, _ifNot.validateIf)(["checkForOutages"], (0, _ifLessThan.validateLessThan)("startOutageThreshold"))]
  };
  _exports.default = _default;
});