define("ember-cli-new-version/components/new-version-notifier/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z/d25MQA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[35,2]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,[[35,6],[35,5],[30,[36,0],[[32,0],\"reload\"],null],[30,[36,0],[[32,0],\"close\"],null]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"update-notification\"],[12],[2,\"\\n      \"],[1,[34,2]],[2,\" \"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[11,\"a\"],[24,6,\"\"],[24,0,\"update-notification__btn\"],[4,[38,0],[[32,0],\"reload\"],null],[12],[1,[34,1]],[13]],\"parameters\":[]}]]],[2,\"\\n      \"],[11,\"a\"],[24,6,\"\"],[24,0,\"update-notification__close\"],[4,[38,0],[[32,0],\"close\"],null],[12],[2,\"×\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"reloadButtonText\",\"message\",\"showReload\",\"if\",\"lastVersion\",\"version\"]}",
    "moduleName": "ember-cli-new-version/components/new-version-notifier/template.hbs"
  });

  _exports.default = _default;
});