define("sentry-advisor/pods/sentry-advisor-models/sentry-advisor-model/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gz/xNver",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"dialogs/sentry-advisor-model\",[],[[\"@sentryAdvisorModel\",\"@onClose\",\"@onDelete\"],[[32,1],[30,[36,0],[\"sentry-advisor-models\"],null],[32,0,[\"onDelete\"]]]],null]],\"hasEval\":false,\"upvars\":[\"transition-to\"]}",
    "moduleName": "sentry-advisor/pods/sentry-advisor-models/sentry-advisor-model/template.hbs"
  });

  _exports.default = _default;
});