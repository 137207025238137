define("sentry-advisor/pods/account/sites/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "efkmZvFR",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"showToolbar\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"app-bars/account\",[],[[\"@account\",\"@title\"],[[32,0,[\"account\"]],[30,[36,0],[\"loading\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[8,\"paper-content\",[[24,0,\"page-content layout-align-center-center flex-grow layout-column\"]],[[\"@padding\"],[false]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-progress-circular\",[],[[\"@primary\",\"@diameter\"],[true,96]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"unless\"]}",
    "moduleName": "sentry-advisor/pods/account/sites/loading/template.hbs"
  });

  _exports.default = _default;
});