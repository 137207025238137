define("sentry-advisor/pods/components/reports/legacy-advanced/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VKFEHd0k",
    "block": "{\"symbols\":[],\"statements\":[[8,\"reports/info-card\",[[24,\"data-test-info-card\",\"\"]],[[\"@onSubmit\",\"@title\",\"@description\"],[[30,[36,0],[[32,0],[30,[36,1],[[32,0,[\"showSendReportForm\"]]],null],true],null],\"v1 Advanced Report\",\"Provides Sentry Advisor Protect Plus Data\"]],null],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"showSendReportForm\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"reports/date-range-dialog\",[[24,\"data-test-dialog\",\"\"]],[[\"@title\",\"@onSubmit\",\"@onCancel\",\"@allDisabled\"],[\"v1 Advanced Report\",[30,[36,0],[[32,0],[32,0,[\"sendReport\"]]],null],[30,[36,0],[[32,0],[30,[36,1],[[32,0,[\"showSendReportForm\"]],false],null]],null],[32,0,[\"emailSent\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"mut\",\"if\"]}",
    "moduleName": "sentry-advisor/pods/components/reports/legacy-advanced/template.hbs"
  });

  _exports.default = _default;
});