define("sentry-advisor/pods/account-group/accounts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RZ2C5P+P",
    "block": "{\"symbols\":[],\"statements\":[[8,\"app-bars/account-group\",[],[[\"@title\"],[[30,[36,0],[\"accounts\"],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"query-manager-view\",[],[[\"@listView\",\"@queryManager\",\"@columns\",\"@onCreate\",\"@listItemComponent\",\"@onItemClick\"],[[32,0,[\"globals\",\"isSmall\"]],[32,0,[\"queryManager\"]],[32,0,[\"columns\"]],[30,[36,1],[\"account-group.accounts.new\"],null],\"account-item\",[32,0,[\"onItemClick\"]]]],null],[2,\"\\n\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"t\",\"transition-to\",\"-outlet\",\"component\"]}",
    "moduleName": "sentry-advisor/pods/account-group/accounts/template.hbs"
  });

  _exports.default = _default;
});