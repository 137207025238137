define("sentry-advisor/pods/components/cards/cycles-day-of-week/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "88ifqOTn",
    "block": "{\"symbols\":[\"@chartTheme\",\"&attrs\",\"@loading\"],\"statements\":[[8,\"paper-card\",[[17,2],[24,\"data-test-cards-cycles-day-of-week\",\"\"],[24,0,\"layout-column\"],[24,5,\"height: 200px;\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    loading\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"high-charts\",[[24,0,\"flex-100\"]],[[\"@content\",\"@chartOptions\",\"@theme\"],[[32,0,[\"chartData\"]],[32,0,[\"chartOptions\"]],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "sentry-advisor/pods/components/cards/cycles-day-of-week/template.hbs"
  });

  _exports.default = _default;
});