define("sentry-advisor/validations/sentry-advisor", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    owner: (0, _validators.validatePresence)(true),
    status: (0, _validators.validatePresence)(true),
    sentryAdvisorModel: (0, _validators.validatePresence)(true),
    esn: (0, _validators.validateFormat)({
      allowBlank: true,
      regex: /^\d{10}$/
    }),
    imei: (0, _validators.validateFormat)({
      allowBlank: true,
      regex: /^\d{15,18}$/
    }),
    mdn: (0, _validators.validateFormat)({
      allowBlank: true,
      regex: /^\d{10}$/
    })
  };
  _exports.default = _default;
});