define("sentry-advisor/pods/account-group/sites/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S21+2xgg",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"showToolbar\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"app-bars/account-group\",[],[[\"@title\"],[[30,[36,0],[\"sites\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[8,\"ui/search-input\",[[16,\"placeholder\",[30,[36,0],[\"searchItem\"],[[\"item\"],[[30,[36,0],[\"sites\"],null]]]]],[24,\"autofocus\",\"true\"]],[[\"@onChange\",\"@value\"],[[32,0,[\"updateSearchString\"]],[32,0,[\"searchString\"]]]],null],[2,\"\\n\"],[8,\"paper-content\",[[24,0,\"page-content\"]],[[\"@padding\"],[false]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"sites-list\",[],[[\"@queryManager\"],[[32,0,[\"queryManager\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "moduleName": "sentry-advisor/pods/account-group/sites/index/template.hbs"
  });

  _exports.default = _default;
});