define("ember-power-calendar/templates/components/power-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TbISN2Fh",
    "block": "{\"symbols\":[\"calendar\",\"&default\"],\"statements\":[[6,[37,8],[[30,[36,7],[[35,1],[30,[36,6],null,[[\"nav\",\"days\"],[[30,[36,4],[[35,5]],[[\"calendar\"],[[30,[36,2],[[35,1]],null]]]],[30,[36,4],[[35,3]],[[\"calendar\"],[[30,[36,2],[[35,1]],null]]]]]]]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,1,[\"nav\"]]],[2,\"\\n    \"],[1,[32,1,[\"days\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"publicAPI\",\"readonly\",\"daysComponent\",\"component\",\"navComponent\",\"hash\",\"assign\",\"with\"]}",
    "moduleName": "ember-power-calendar/templates/components/power-calendar.hbs"
  });

  _exports.default = _default;
});