define("sentry-advisor/pods/components/dialogs/sentry-advisor/forms/decomission/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "usqtUwoN",
    "block": "{\"symbols\":[],\"statements\":[[8,\"paper-dialog-content\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"are-you-sure-you-want-to-action\"],[[\"action\"],[[30,[36,0],[\"decommission\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"paper-dialog-actions\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"span\"],[14,0,\"flex\"],[12],[13],[2,\"\\n  \"],[8,\"paper-button\",[],[[\"@onClick\"],[[32,0,[\"onCancel\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"no\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"paper-button\",[],[[\"@primary\",\"@onClick\"],[true,[32,0,[\"onSave\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"yes\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "sentry-advisor/pods/components/dialogs/sentry-advisor/forms/decomission/template.hbs"
  });

  _exports.default = _default;
});