define("sentry-advisor/pods/components/charts/chart-wrapper/selectors/from-to-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W/6wrkvj",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[6,[37,5],null,[[\"class\"],[\"flex-nogrow\"]],[[\"default\"],[{\"statements\":[[2,\"From \"],[1,[30,[36,2],[[35,1],[35,0]],null]],[2,\" to \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[6,[37,5],null,[[\"class\"],[\"flex-nogrow\"]],[[\"default\"],[{\"statements\":[[2,\"From \"],[1,[30,[36,2],[[35,1],[35,0]],null]],[2,\" to \"],[1,[30,[36,2],[[35,4],[35,3]],null]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"startFormat\",\"startDate\",\"moment-format\",\"endFormat\",\"endDate\",\"paper-subheader\",\"unless\"]}",
    "moduleName": "sentry-advisor/pods/components/charts/chart-wrapper/selectors/from-to-text/template.hbs"
  });

  _exports.default = _default;
});