define("sentry-advisor/pods/components/app-bars/account-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ck8UrwLe",
    "block": "{\"symbols\":[\"toolbar\",\"menu\",\"content\"],\"statements\":[[8,\"paper-toolbar\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"tools\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-button\",[],[[\"@class\",\"@iconButton\",\"@onClick\"],[\"hide-gt-sm\",true,[30,[36,2],[[32,0],[30,[36,1],[[35,0]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        menu\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"h3\"],[12],[2,\"\\n      \"],[1,[35,3,[\"name\"]]],[2,\" - \"],[1,[30,[36,5],[[35,4]],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"flex\"],[12],[13],[2,\"\\n    \"],[8,\"paper-menu\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"trigger\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"paper-button\",[],[[\"@iconButton\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            more_vert\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,[32,2,[\"content\"]],[],[[\"@width\"],[4]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"app-bars/user-manual-menu-item\",[],[[\"@content\"],[[32,3]]],null],[2,\"\\n        \"],[8,\"tos-menu-item\",[],[[\"@content\"],[[32,3]]],null],[2,\"\\n        \"],[8,\"sign-out-menu-item\",[],[[\"@content\"],[[32,3]]],null],[2,\"\\n      \"]],\"parameters\":[3]}]]],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"mainMenuOpened\",\"mut\",\"action\",\"accountGroup\",\"title\",\"titleize\"]}",
    "moduleName": "sentry-advisor/pods/components/app-bars/account-group/template.hbs"
  });

  _exports.default = _default;
});