define("htt-ui/components/ui/cells/log-type-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PaperIcon data-test-log-type-icon>
    {{if (eq @value "service-log") "construction" "support_agent"}}
  </PaperIcon>
  */
  {
    "id": "MmvMG156",
    "block": "{\"symbols\":[\"@value\"],\"statements\":[[8,\"paper-icon\",[[24,\"data-test-log-type-icon\",\"\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,1],[[30,[36,0],[[32,1],\"service-log\"],null],\"construction\",\"support_agent\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\"]}",
    "moduleName": "htt-ui/components/ui/cells/log-type-icon.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});