define("sentry-advisor/pods/components/sign-out-menu-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D/1aqA6R",
    "block": "{\"symbols\":[\"content\"],\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,0,[\"content\"]]],null,[[\"default\"],[{\"statements\":[[8,[32,1,[\"menu-item\"]],[],[[\"@onClick\"],[[30,[36,0],[[32,0],\"signOut\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"exit_to_app\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"span\"],[12],[1,[30,[36,1],[\"sign-out\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"with\"]}",
    "moduleName": "sentry-advisor/pods/components/sign-out-menu-item/template.hbs"
  });

  _exports.default = _default;
});