define("sentry-advisor/pods/components/site-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q3Yo82I6",
    "block": "{\"symbols\":[\"@site\"],\"statements\":[[8,\"paper-item\",[],[[\"@class\",\"@onClick\"],[[31,[\"md-2-line sa-site-item \",[30,[36,0],[[32,1,[\"hasActiveAlarm\"]],\"in-alarm\",\"all-clear\"],null]]],[32,0,[\"transitionToSite\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,1,[\"hasActiveAlarm\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"htt-icon\",[],[[\"@class\",\"@icon\"],[\"md-avatar-icon\",\"alert\"]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,1,[\"offline\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"paper-icon\",[],[[\"@class\"],[\"md-avatar-icon offline\"]],[[\"default\"],[{\"statements\":[[2,\"wifi_off\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"paper-icon\",[],[[\"@class\"],[\"md-avatar-icon\"]],[[\"default\"],[{\"statements\":[[2,\"check\"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[10,\"div\"],[14,0,\"md-list-item-text\"],[12],[2,\"\\n        \"],[10,\"h3\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n        \"],[10,\"p\"],[12],[1,[32,0,[\"sentryAdvisorText\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "sentry-advisor/pods/components/site-item/template.hbs"
  });

  _exports.default = _default;
});