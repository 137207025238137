define("sentry-advisor/pods/components/main-menu/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ve9IxHII",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"main-menu-header\"],[14,\"data-test-main-menu-header\",\"\"],[12],[2,\"\\n  \"],[10,\"h2\"],[14,0,\"main-menu-header-title\"],[12],[1,[30,[36,0],[\"sentry-advisor-title\"],null]],[13],[2,\"\\n  \"],[10,\"h5\"],[14,0,\"main-menu-header-sub-title\"],[12],[1,[30,[36,0],[\"by\"],null]],[2,\" \"],[10,\"a\"],[14,6,\"http://eone.com\"],[12],[2,\"E/One\"],[13],[13],[2,\"\\n  \"],[10,\"h5\"],[14,0,\"main-menu-header-sub-title\"],[12],[1,[30,[36,0],[\"powered-by\"],null]],[2,\" \"],[10,\"a\"],[14,6,\"http://htt.io\"],[12],[2,\"HTT\"],[13],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "sentry-advisor/pods/components/main-menu/header/template.hbs"
  });

  _exports.default = _default;
});