define("sentry-advisor/pods/components/distributor-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GLv8Geon",
    "block": "{\"symbols\":[\"distributor\",\"autocomplete\"],\"statements\":[[8,\"paper-autocomplete\",[],[[\"@triggerClass\",\"@selected\",\"@defaultHighlighted\",\"@search\",\"@labelPath\",\"@label\",\"@placeholder\",\"@loadingMessage\",\"@noMatchesMessage\",\"@allowClear\",\"@isTouched\",\"@errors\",\"@onSelectionChange\"],[\"flex\",[32,0,[\"distributor\"]],true,[30,[36,3],[[35,2]],null],\"name\",[34,4],[30,[36,5],[\"selectItem\"],[[\"item\"],[[35,4]]]],[30,[36,5],[\"searchingItem\"],[[\"item\"],[[30,[36,5],[\"account-groups\"],null]]]],[30,[36,5],[\"no-item\"],[[\"item\"],[[30,[36,5],[\"account-group\"],null]]]],false,true,[32,0,[\"errors\"]],[32,0,[\"onChange\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"paper-autocomplete-highlight\",[],[[\"@label\",\"@searchText\",\"@flags\"],[[32,1,[\"name\"]],[32,2,[\"searchText\"]],\"i\"]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"paper-button\",[],[[\"@iconButton\",\"@onClick\"],[true,[30,[36,1],[[32,0],[35,0],null],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"clear\"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"onChange\",\"action\",\"searchDistributors\",\"perform\",\"label\",\"t\",\"distributor\",\"if\"]}",
    "moduleName": "sentry-advisor/pods/components/distributor-selector/template.hbs"
  });

  _exports.default = _default;
});