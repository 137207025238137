define("sentry-advisor/pods/components/dialogs/sentry-advisor/forms/assign/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YBHUbGgh",
    "block": "{\"symbols\":[\"owner\"],\"statements\":[[8,\"paper-dialog-content\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"distributor-customer-selector\",[],[[\"@label\",\"@selected\",\"@isTouched\",\"@onChange\",\"@errors\"],[[30,[36,0],[\"owner\"],null],[32,0,[\"changeset\",\"owner\"]],[32,0,[\"isTouched\"]],[30,[36,2],[[32,0],[30,[36,1],[[32,0,[\"changeset\",\"owner\"]]],null]],null],[32,0,[\"changeset\",\"error\",\"owner\",\"validation\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,1,[\"name\"]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"paper-dialog-actions\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"span\"],[14,0,\"flex\"],[12],[13],[2,\"\\n  \"],[8,\"paper-button\",[],[[\"@onClick\"],[[32,0,[\"onCancel\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"cancel\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"paper-button\",[],[[\"@primary\",\"@disabled\",\"@onClick\"],[true,[32,0,[\"changeset\",\"isInvalid\"]],[32,0,[\"onSave\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"save\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"mut\",\"action\"]}",
    "moduleName": "sentry-advisor/pods/components/dialogs/sentry-advisor/forms/assign/template.hbs"
  });

  _exports.default = _default;
});