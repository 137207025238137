define("ember-paper-flatpickr/templates/components/paper-flatpickr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fm7/uJV7",
    "block": "{\"symbols\":[\"&attrs\",\"&default\",\"@allowClear\"],\"statements\":[[11,\"div\"],[24,0,\"layout layout-align-center-center\"],[24,5,\"position: relative;\"],[17,1],[12],[2,\"\\n  \\n\"],[18,2,[[30,[36,3],null,[[\"input\"],[[30,[36,2],[\"paper-flatpickr-input\"],[[\"registerInput\",\"unregisterInput\",\"class\"],[[30,[36,0],[[32,0],\"registerInput\"],null],[30,[36,0],[[32,0],\"unregisterInput\"],null],\"layout layout-align-center-center flex\"]]]]]]]],[2,\"\\n\\n\"],[6,[37,6],[[30,[36,5],[[32,0,[\"input\",\"value\"]],[32,3],[30,[36,4],[[32,0,[\"input\",\"disabled\"]]],null],[32,0,[\"input\",\"disableMobile\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"button\"],[15,\"onmouseup\",[30,[36,0],[[32,0],\"clear\"],null]],[14,5,\"\\n      display: block;\\n      position: absolute;\\n      right: 0;\\n      width: 30px;\\n      height: 30px;\\n      line-height: 20px;\\n      text-align: center;\\n      border: none;\\n      border-radius: 50%;\\n      background: 0 0;\\n      padding: 0;\\n      outline:0;\\n    \"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"close\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"paper-icon\",\"component\",\"hash\",\"not\",\"and\",\"if\"]}",
    "moduleName": "ember-paper-flatpickr/templates/components/paper-flatpickr.hbs"
  });

  _exports.default = _default;
});