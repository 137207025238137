define("sentry-advisor/pods/components/dialogs/sentry-advisor-site-delete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "in83E3lK",
    "block": "{\"symbols\":[\"@onClose\"],\"statements\":[[8,\"paper-dialog\",[],[[\"@class\",\"@fullscreen\"],[\"flex-gt-sm-50\",true]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[8,\"paper-dialog-toolbar\",[],[[\"@title\",\"@onClose\"],[[30,[36,0],[\"sa-site-settings\"],null],[32,1]]],null],[2,\"\\n\\n    \"],[8,\"paper-dialog-content\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"p\"],[12],[1,[32,0,[\"promptText\"]]],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"layout-row\"],[12],[2,\"\\n            \"],[8,\"paper-input\",[],[[\"@onChange\",\"@value\"],[[30,[36,2],[[30,[36,1],[[32,0,[\"confirmationTextEntered\"]]],null]],null],[32,0,[\"confirmationTextEntered\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"paper-dialog-actions\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"span\"],[14,0,\"flex\"],[12],[13],[2,\"\\n        \"],[8,\"paper-button\",[],[[\"@disabled\",\"@onClick\"],[[30,[36,3],[[32,0,[\"isConfirmed\"]]],null],[32,0,[\"deleteSite\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[\"delete\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"mut\",\"fn\",\"not\"]}",
    "moduleName": "sentry-advisor/pods/components/dialogs/sentry-advisor-site-delete/template.hbs"
  });

  _exports.default = _default;
});