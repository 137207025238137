define("sentry-advisor/pods/account/reports/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6jHUI409",
    "block": "{\"symbols\":[\"report\",\"Report\"],\"statements\":[[8,\"app-bars/account\",[],[[\"@account\",\"@title\"],[[32,0,[\"account\"]],[30,[36,3],[\"reports\"],null]]],null],[2,\"\\n\\n\"],[8,\"paper-content\",[[24,0,\"flex\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"layout-row layout-wrap\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"reports\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,0],[\"reports/\",[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"           \"],[8,[32,2],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"component\",\"let\",\"t\",\"-track-array\",\"each\"]}",
    "moduleName": "sentry-advisor/pods/account/reports/template.hbs"
  });

  _exports.default = _default;
});