define("ember-leaflet/helpers/icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.icon = void 0;
  var isFastBoot = typeof FastBoot !== 'undefined';
  /* global L */

  /**
   * Represents an icon to provide when creating a marker.
   * More information about its possible options [here](https://leafletjs.com/reference-1.7.1.html#icon-option).
   *
   * @function icon
   * @param {Object} options the Icon options object
   * @return {Icon}
   */

  var icon = isFastBoot ? function () {} : function icon(_, hash) {
    // https://github.com/emberjs/ember.js/issues/14668
    var options = Object.assign({}, hash);
    return L.icon(options);
  };
  _exports.icon = icon;

  var _default = Ember.Helper.helper(icon);

  _exports.default = _default;
});