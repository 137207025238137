define("sentry-advisor/pods/components/main-menu/billing-report-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/KfS2YwR",
    "block": "{\"symbols\":[],\"statements\":[[8,\"paper-item\",[[24,0,\"site-side-nav-link\"]],[[\"@onClick\"],[[30,[36,4],[[32,0],\"openDialog\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[1,\"attach_money\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,1],[\"billing-report\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,5],[[32,0,[\"isDialogOpen\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"paper-dialog\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-dialog-content\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"span\"],[14,5,\"text-transform: capitalize;\"],[12],[1,[30,[36,1],[\"download-item\"],[[\"item\"],[[30,[36,1],[\"billing-report\"],null]]]]],[13],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[2,\"\\n      \"],[8,\"paper-input\",[],[[\"@type\",\"@label\",\"@value\",\"@onChange\"],[\"email\",\"Enter Email\",[34,2],[30,[36,4],[[32,0],[30,[36,3],[[35,2]],null]],null]]],null],[2,\"\\n\"],[6,[37,5],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"p\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"dialog-submit-actions\",[],[[\"@submitType\",\"@onCancel\",\"@onSubmit\",\"@allDisabled\"],[\"send\",[30,[36,4],[[32,0],\"closeDialog\"],null],[30,[36,4],[[32,0],\"sendReport\"],null],[32,0,[\"emailSent\"]]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"message\",\"t\",\"email\",\"mut\",\"action\",\"if\"]}",
    "moduleName": "sentry-advisor/pods/components/main-menu/billing-report-link/template.hbs"
  });

  _exports.default = _default;
});