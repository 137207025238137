define("sentry-advisor/pods/account/alarm-groups/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qT9vMI6g",
    "block": "{\"symbols\":[],\"statements\":[[8,\"app-bars/account\",[],[[\"@account\",\"@title\"],[[32,0,[\"account\"]],[30,[36,0],[\"alarmgroups\"],null]]],null],[2,\"\\n\\n\"],[8,\"ui/search-input\",[[24,\"autofocus\",\"true\"]],[[\"@onChange\",\"@value\"],[[32,0,[\"updateSearchString\"]],[32,0,[\"searchString\"]]]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"layout-row flex\"],[12],[2,\"\\n  \"],[8,\"paper-content\",[[24,0,\"flex-grow layout-column\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"alarm-groups-list\",[],[[\"@alarmGroups\",\"@queryManager\",\"@account\"],[[32,0,[\"account\",\"alarmGroups\"]],[32,0,[\"queryManager\"]],[32,0,[\"account\"]]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "sentry-advisor/pods/account/alarm-groups/template.hbs"
  });

  _exports.default = _default;
});