define("sentry-advisor/pods/account-group/accounts/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6fW2fc3J",
    "block": "{\"symbols\":[],\"statements\":[[8,\"dialogs/account\",[],[[\"@account\",\"@editing\",\"@title\",\"@onCancel\",\"@onClose\"],[[32,0,[\"model\"]],true,[30,[36,0],[\"newItem\"],[[\"item\"],[[30,[36,0],[\"account\"],null]]]],[30,[36,1],[\"account-group.accounts\"],null],[32,0,[\"afterCreate\"]]]],null]],\"hasEval\":false,\"upvars\":[\"t\",\"transition-to\"]}",
    "moduleName": "sentry-advisor/pods/account-group/accounts/new/template.hbs"
  });

  _exports.default = _default;
});