define("query-manager/utils/params", ["exports", "query-manager/utils/query-manager"], function (_exports, _queryManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildQueryParams = buildQueryParams;
  _exports.onQueryUpdate = onQueryUpdate;
  _exports.parseQueryParams = parseQueryParams;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  function buildQueryParams(config) {
    return [].concat(_toConsumableArray(Object.keys(config)), _toConsumableArray(_queryManager.QueryManagerOptions));
  }
  function parseQueryParams(params, defaultFilters) {
    var paramsKeys = Object.keys(params);
    var filters = Object.keys(defaultFilters).reduce(function (acc, key) {
      if (params[key] !== undefined) {
        paramsKeys.includes(key) && (acc[key] = params[key]);
      }
      return acc;
    }, {});
    var options = _queryManager.QueryManagerOptions.reduce(function (acc, key) {
      if (params[key] !== undefined) {
        paramsKeys.includes(key) && (acc[key] = params[key]);
      }
      return acc;
    }, {});
    return {
      filters: filters,
      options: options
    };
  }
  function onQueryUpdate(queryOptions) {
    var _this = this;
    var optionsKeys = Object.keys(queryOptions);
    this.queryParams.forEach(function (param) {
      return optionsKeys.includes(param) && _this.set(param, queryOptions[param]);
    });
  }
});