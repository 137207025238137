define("htt-shared-models/models/htt-controls/actions/action-types", ["exports", "htt-shared-models/models/htt-controls/actions/analog-output", "htt-shared-models/models/htt-controls/actions/analog-output-step", "htt-shared-models/models/htt-controls/actions/assign-group-state", "htt-shared-models/models/htt-controls/actions/clear-timer", "htt-shared-models/models/htt-controls/actions/digital-input-write", "htt-shared-models/models/htt-controls/actions/digital-output-on-off", "htt-shared-models/models/htt-controls/actions/installation-turn-off", "htt-shared-models/models/htt-controls/actions/installation-turn-on", "htt-shared-models/models/htt-controls/actions/installation-turn-on-exactly", "htt-shared-models/models/htt-controls/actions/pump-group-force-alternate", "htt-shared-models/models/htt-controls/actions/pump-group-turn-off", "htt-shared-models/models/htt-controls/actions/pump-group-turn-on", "htt-shared-models/models/htt-controls/actions/pump-group-turn-on-exactly", "htt-shared-models/models/htt-controls/actions/pump-turn-off", "htt-shared-models/models/htt-controls/actions/pump-turn-on", "htt-shared-models/models/htt-controls/actions/set-timer"], function (_exports, _analogOutput, _analogOutputStep, _assignGroupState, _clearTimer, _digitalInputWrite, _digitalOutputOnOff, _installationTurnOff, _installationTurnOn, _installationTurnOnExactly, _pumpGroupForceAlternate, _pumpGroupTurnOff, _pumpGroupTurnOn, _pumpGroupTurnOnExactly, _pumpTurnOff, _pumpTurnOn, _setTimer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ActionTypes = [_assignGroupState.HttControlsActionsAssignGroupStateType, _analogOutput.HttControlsActionsAnalogOutputType, _analogOutputStep.HttControlsActionsAnalogOutputStepType, _clearTimer.HttControlsActionsClearTimerType, _digitalInputWrite.HttControlsActionsDigitalInputWriteType, _digitalOutputOnOff.HttControlsActionsDigitalOutputOnOffType, _installationTurnOff.HttControlsActionsInstallationTurnOffType, _installationTurnOnExactly.HttControlsActionsInstallationTurnOnExactlyType, _installationTurnOn.HttControlsActionsInstallationTurnOnType, _pumpGroupTurnOff.HttControlsActionsPumpGroupTurnOffType, _pumpGroupTurnOnExactly.HttControlsActionsPumpGroupTurnOnExactlyType, _pumpGroupTurnOn.HttControlsActionsPumpGroupTurnOnType, _pumpGroupForceAlternate.HttControlsActionsPumpGroupForceAlternateType, _pumpTurnOff.HttControlsActionsPumpTurnOffType, _pumpTurnOn.HttControlsActionsPumpTurnOnType, _setTimer.HttControlsActionsSetTimerType];
  var _default = ActionTypes;
  _exports.default = _default;
});