define("sentry-advisor/pods/components/cards/cycles-aggregated-chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/ZAyz3NL",
    "block": "{\"symbols\":[\"@chartTheme\",\"@type\",\"&attrs\",\"@loading\"],\"statements\":[[8,\"paper-card\",[[17,3],[24,\"data-test-cards-cycles-aggregated-chart\",\"\"],[24,0,\"layout-column\"],[24,5,\"min-height: 200px;\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    loading\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,2],\"runtime\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"high-charts\",[[24,0,\"flex-100\"]],[[\"@mode\",\"@content\",\"@chartOptions\",\"@theme\"],[\"StockChart\",[32,0,[\"chartData\"]],[32,0,[\"chartOptions\"]],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,2],\"cycles\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"high-charts\",[[24,0,\"flex-100\"]],[[\"@mode\",\"@content\",\"@chartOptions\",\"@theme\"],[\"StockChart\",[32,0,[\"chartData\"]],[32,0,[\"chartOptions\"]],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[8,\"high-charts\",[[24,0,\"flex-100\"]],[[\"@mode\",\"@content\",\"@chartOptions\",\"@theme\"],[\"StockChart\",[32,0,[\"chartData\"]],[32,0,[\"chartOptions\"]],[32,1]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\"]}",
    "moduleName": "sentry-advisor/pods/components/cards/cycles-aggregated-chart/template.hbs"
  });

  _exports.default = _default;
});