define("sentry-advisor/pods/sentry-advisor-models/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zeu0zIPv",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"app-bars/admin\",[],[[\"@title\"],[[30,[36,0],[\"sentry-advisor-models\"],null]]],null],[2,\"\\n\\n\"],[8,\"query-manager-view\",[],[[\"@listView\",\"@onItemClick\",\"@queryManager\",\"@columns\",\"@listItemComponent\",\"@onCreate\"],[[32,0,[\"globals\",\"isSmall\"]],[32,0,[\"onItemClick\"]],[32,1],[32,0,[\"columns\"]],\"sentry-advisor-model-item\",[30,[36,3],[[30,[36,2],[\"create sentryAdvisorModel\"],null],[30,[36,1],[\"sentry-advisor-models.new\"],null]],null]]],null],[2,\"\\n\\n\"],[1,[30,[36,5],[[30,[36,4],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"t\",\"transition-to\",\"can\",\"if\",\"-outlet\",\"component\"]}",
    "moduleName": "sentry-advisor/pods/sentry-advisor-models/template.hbs"
  });

  _exports.default = _default;
});