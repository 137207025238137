define("sentry-advisor/pods/account/sites/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OFFGy4u8",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,0,[\"showToolbar\"]]],null,[[\"default\"],[{\"statements\":[[8,\"app-bars/account\",[],[[\"@account\",\"@title\"],[[32,0,[\"account\"]],[30,[36,0],[\"sites\"],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"-outlet\",\"component\"]}",
    "moduleName": "sentry-advisor/pods/account/sites/template.hbs"
  });

  _exports.default = _default;
});