define("sentry-advisor/pods/components/query-manager-view-filters/pump-cycle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e4w5Z251",
    "block": "{\"symbols\":[\"pumpSelection\",\"index\",\"@installation\"],\"statements\":[[8,\"paper-content\",[],[[\"@padding\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"layout-column\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"md-padding border-bottom\"],[14,\"data-test-query-manager-view-filters-pump-cycle\",\"\"],[12],[2,\"\\n      \"],[8,\"ui/inputs/datepicker\",[],[[\"@allowClear\",\"@class\",\"@label\",\"@date\",\"@maxDate\",\"@onChange\",\"@timezone\"],[true,\"flex-grow\",[30,[36,1],[\"startDate\"],null],[32,0,[\"startDate\"]],[32,0,[\"endDate\"]],[32,0,[\"updateStartDate\"]],[32,3,[\"timezone\"]]]],null],[2,\"\\n      \"],[8,\"ui/inputs/datepicker\",[],[[\"@allowClear\",\"@class\",\"@label\",\"@date\",\"@minDate\",\"@onChange\",\"@timezone\"],[true,\"flex-grow\",[30,[36,1],[\"endDate\"],null],[32,0,[\"endDate\"]],[32,0,[\"startDate\"]],[32,0,[\"updateEndDate\"]],[32,3,[\"timezone\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"pt-4 layout-column\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"selectablePumps\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"paper-checkbox\",[],[[\"@label\",\"@value\",\"@onChange\"],[[32,1,[\"pump\",\"name\"]],[32,1,[\"selected\"]],[30,[36,0],[[32,0,[\"toggleSelectablePump\"]],[32,2]],null]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"layout-row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"flex-grow\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"flex-noshrink\"],[12],[2,\"\\n        \"],[8,\"query-manager-view-filters/pump-cycle/download-button\",[],[[\"@installation\"],[[32,3]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"t\",\"-track-array\",\"each\"]}",
    "moduleName": "sentry-advisor/pods/components/query-manager-view-filters/pump-cycle/template.hbs"
  });

  _exports.default = _default;
});