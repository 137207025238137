define('ember-paper-stepper/components/paper-step', ['exports', 'ember-paper-stepper/templates/components/paper-step', 'ember-composability-tools'], function (exports, _paperStep, _emberComposabilityTools) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberComposabilityTools.ChildMixin, {
    layout: _paperStep.default,
    tagName: 'md-step',
    classNameBindings: ['vertical::layout-column', 'isActiveAndHorizontal:flex'],
    bodyComponent: 'paper-step-body',
    actionsComponent: 'paper-step-actions',

    optionalLabel: 'Optional',

    isActive: Ember.computed('orderedIndex', 'currentStep', function () {
      return this.get('orderedIndex') === this.get('currentStep');
    }),

    isActiveAndHorizontal: Ember.computed('isActive', 'vertical', function () {
      return this.get('isActive') && !this.get('vertical');
    }),

    isCompleted: Ember.computed('orderedIndex', 'currentStep', 'linear', function () {
      var _getProperties = this.getProperties('orderedIndex', 'currentStep', 'linear'),
          orderedIndex = _getProperties.orderedIndex,
          currentStep = _getProperties.currentStep,
          linear = _getProperties.linear;

      return linear && orderedIndex < currentStep;
    }),

    hasError: Ember.computed.bool('error'),

    showOptional: Ember.computed.or('optional', 'hasError'),

    stepNumberLabel: Ember.computed('orderedIndex', function () {
      return this.get('orderedIndex') + 1;
    }),

    isButtonDisabled: Ember.computed.or('linear', 'isActive')
  });
});