define("sentry-advisor/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    this.route("account-group", {
      path: "/account-group/:distributor_id"
    }, function () {
      this.route("accounts", function () {
        this.route("account", {
          path: "/:account_id"
        }, function () {
          this.route("edit");
        });
        this.route("new");
      });
      this.route("alarms");
      this.route("sentry-advisors", function () {
        this.route("sentry-advisor", {
          path: "/:sa_id"
        });
        this.route("new");
        this.route("upload");
      });
      this.route("sites", function () {
        this.route("map");
      });
      this.route("sub-account-groups", function () {
        this.route("account-group", {
          path: "/:sub_account_group_id"
        });
        this.route("new");
      });
      this.route("users", function () {
        this.route("user", {
          path: "/:user_id"
        });
        this.route("new");
      });
    });
    this.route("account", {
      path: "/account/:account_id"
    }, function () {
      this.route("users", function () {
        this.route("user", {
          path: "/:user_id"
        });
        this.route("new");
      });
      this.route("sentry-advisors", function () {
        this.route("sentry-advisor", {
          path: "/:sa2_id"
        });
        this.route("new");
        this.route("upload");
      });
      this.route("alarm-groups");
      this.route("alarms");
      this.route("settings");
      this.route("reports");
      this.route("sites-group", {
        path: "/sites-group/:group_id"
      }, function () {
        this.route("sites");
        this.route("map");
      });
      this.route("notification-settings", function () {
        this.route("sites-group", {
          path: "/sites-group/:sites_group_id"
        });
      });
      this.route("sites", function () {
        this.route("site", {
          path: "/site/:site_id"
        });
        this.route("map");
      });
      this.route("notes", function () {
        this.route("note", {
          path: "/:note_id"
        }, function () {
          this.route("edit");
        });
        this.route("new");
      });
    });
    this.route("sentry-advisor-models", function () {
      this.route("sentry-advisor-model", {
        path: "/:model_id"
      });
      this.route("new");
    });
  });

  _exports.default = _default;
});