define("sentry-advisor/pods/account-group/sub-account-groups/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LOxwD6aU",
    "block": "{\"symbols\":[],\"statements\":[[8,\"app-bars/account-group\",[],[[\"@title\"],[[30,[36,0],[\"account-groups\"],null]]],null],[2,\"\\n\\n\"],[8,\"query-manager-view\",[],[[\"@listView\",\"@queryManager\",\"@onCreate\",\"@listItemComponent\",\"@columns\",\"@onItemClick\"],[[32,0,[\"globals\",\"isSmall\"]],[32,0,[\"queryManager\"]],[30,[36,1],[\"account-group.sub-account-groups.new\",[32,0,[\"accountGroup\"]]],null],\"distributor-item\",[32,0,[\"columns\"]],[32,0,[\"onItemClick\"]]]],null],[2,\"\\n\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"t\",\"transition-to\",\"-outlet\",\"component\"]}",
    "moduleName": "sentry-advisor/pods/account-group/sub-account-groups/template.hbs"
  });

  _exports.default = _default;
});