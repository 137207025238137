define("sentry-advisor/pods/components/main-menu/link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MyxtJuEa",
    "block": "{\"symbols\":[\"@icon\",\"@httIcon\",\"@title\"],\"statements\":[[8,\"paper-item\",[[16,0,[31,[\"site-side-nav-link \",[34,0]]]]],[[\"@onClick\"],[[30,[36,1],[[32,0],\"transitionTo\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"htt-icon\",[],[[\"@icon\"],[[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[1,[32,1]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"p\"],[12],[1,[32,3]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"itemClass\",\"action\",\"if\"]}",
    "moduleName": "sentry-advisor/pods/components/main-menu/link/template.hbs"
  });

  _exports.default = _default;
});