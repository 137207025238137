define("ember-paper/mixins/validation-mixin", ["exports", "ember-paper/validators/required", "ember-paper/validators/min", "ember-paper/validators/max", "ember-paper/validators/minlength", "ember-paper/validators/maxlength", "ember-invoke-action"], function (_exports, _required, _min, _max, _minlength, _maxlength, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  /**
   * In order to make validation generic it is required that components using the validation mixin
   * specify what property the validation logic should be based on.
   *
   * @public
   *
   * @return computed property that depends on the supplied property name
   */
  function buildComputedValidationMessages(property) {
    var _Ember;

    var validations = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var customValidations = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    var validationParams = validations.map(function (v) {
      return Ember.get(v, 'param');
    }).filter(function (v) {
      return !Ember.isBlank(v);
    });
    var customValidationParams = customValidations.map(function (v) {
      return Ember.get(v, 'param');
    }).filter(function (v) {
      return !Ember.isBlank(v);
    });
    return (_Ember = Ember).computed.apply(_Ember, [property, 'errors.[]', 'customValidations.[]'].concat(_toConsumableArray(validationParams), _toConsumableArray(customValidationParams), [function () {
      var _this = this;

      var validations = Ember.A();
      var messages = Ember.A(); // built-in validations

      validations.pushObjects(this.validations()); // custom validations

      var customValidations = this.get('customValidations');
      (false && !(Ember.isArray(customValidations)) && Ember.assert('`customValidations` must be an array', Ember.isArray(customValidations)));
      validations.pushObjects(customValidations); // execute validations

      var currentValue = this.get(property);
      validations.forEach(function (validation) {
        (false && !(validation && validation.validate && typeof validation.validate === 'function') && Ember.assert('validation must include a `validate(value)` function', validation && validation.validate && typeof validation.validate === 'function'));

        try {
          var valParam = Ember.get(validation, 'param');
          var paramValue = valParam ? _this.get(valParam) : undefined;

          if (!validation.validate(currentValue, paramValue)) {
            var message = _this.get("errorMessages.".concat(valParam)) || Ember.get(validation, 'message');
            messages.pushObject({
              message: Ember.String.loc(message.string || message, paramValue, currentValue)
            });
          }
        } catch (error) {
          (false && Ember.warn("Exception with validation: ".concat(validation, " ").concat(error), false));
        }
      }); // error messages array and string

      var errors = this.get('errors') || [];
      (false && !(Ember.isArray(errors) || typeof errors === 'string' || errors instanceof String) && Ember.assert('`errors` must be an array or string', Ember.isArray(errors) || typeof errors === 'string' || errors instanceof String));

      if (Ember.isArray(errors)) {
        messages.pushObjects(errors.map(function (e) {
          return Ember.get(e, 'message') ? e : {
            message: e
          };
        }));
      } else if (typeof errors === 'string' || errors instanceof String) {
        messages.pushObject({
          message: errors
        });
      }

      return messages;
    }]));
  }
  /**
   * @class ValidationMixin
   * @extends Ember.Mixin
   */


  var _default = Ember.Mixin.create({
    validationErrorMessages: null,
    lastIsInvalid: undefined,
    validationProperty: null,
    // property that validation should be based on
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(this.get('validationProperty')) && Ember.assert('validationProperty must be set', this.get('validationProperty')));

      if (!this.get('validationErrorMessages')) {
        var computedValidationMessages = buildComputedValidationMessages(this.get('validationProperty'), this.validations(), this.get('customValidations'));
        Ember.defineProperty(this, 'validationErrorMessages', computedValidationMessages);
      }
    },
    hasErrorMessages: Ember.computed.bool('validationErrorMessages.length'),

    /**
     * The result of isInvalid is appropriate for controlling the display of
     * validation error messages. It also may be used to distinguish whether
     * the input would be considered valid after it is touched.
     *
     * @public
     *
     * @return {boolean} Whether the input is or would be invalid.
     *    false: input is valid (touched or not), or is no longer rendered
     *    true: input has been touched and is invalid.
     */
    isInvalid: Ember.computed.reads('hasErrorMessages'),
    isValid: Ember.computed.not('isInvalid'),

    /**
     * Return the built-in validations.
     *
     * May be overridden to provide additional built-in validations. Be sure to
     * call this._super() to retrieve the standard validations.
     *
     * @public
     */
    validations: function validations() {
      return [_required.default, _min.default, _max.default, _minlength.default, _maxlength.default];
    },
    notifyValidityChange: function notifyValidityChange() {
      var isValid = this.get('isValid');
      var lastIsValid = this.get('lastIsValid');
      var isTouched = this.get('isTouched');
      var lastIsTouched = this.get('lastIsTouched');

      if (lastIsValid !== isValid || lastIsTouched !== isTouched) {
        (0, _emberInvokeAction.invokeAction)(this, 'onValidityChange', isValid);
        this.set('lastIsValid', isValid);
        this.set('lastIsTouched', isTouched);
      }
    },
    customValidations: [],
    errors: []
  });

  _exports.default = _default;
});