define("sentry-advisor/pods/account/users/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4z7HFcqy",
    "block": "{\"symbols\":[],\"statements\":[[8,\"app-bars/account\",[],[[\"@account\",\"@title\"],[[32,0,[\"account\"]],[30,[36,0],[\"user.many\"],null]]],null],[2,\"\\n\\n\"],[8,\"query-manager-view\",[],[[\"@listView\",\"@queryManager\",\"@columns\",\"@listItemComponent\",\"@onItemClick\",\"@onCreate\"],[[32,0,[\"globals\",\"isSmall\"]],[32,0,[\"queryManager\"]],[32,0,[\"columns\"]],[30,[36,1],[\"user-item\"],[[\"compressed\"],[true]]],[32,0,[\"onItemClick\"]],[30,[36,2],[\"account.users.new\"],null]]],null],[2,\"\\n\"],[1,[30,[36,1],[[30,[36,3],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"t\",\"component\",\"transition-to\",\"-outlet\"]}",
    "moduleName": "sentry-advisor/pods/account/users/template.hbs"
  });

  _exports.default = _default;
});