define("sentry-advisor/pods/account-group/sites/map/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JXybW3Ir",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"showToolbar\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"app-bars/account-group\",[],[[\"@title\"],[[30,[36,0],[\"sites\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[8,\"paper-content\",[[24,0,\"page-content layout-column\"]],[[\"@padding\"],[false]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"sites-map\",[],[[\"@sites\",\"@clusterMarkers\"],[[32,0,[\"sites\"]],true]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "moduleName": "sentry-advisor/pods/account-group/sites/map/template.hbs"
  });

  _exports.default = _default;
});