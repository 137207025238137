define("sentry-advisor/pods/account/sites-group/sites/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HrIKepSs",
    "block": "{\"symbols\":[\"site\"],\"statements\":[[8,\"ui/search-input\",[[16,\"placeholder\",[30,[36,0],[\"searchItem\"],[[\"item\"],[[30,[36,0],[\"sites\"],null]]]]],[24,\"autofocus\",\"true\"]],[[\"@onChange\",\"@value\"],[[32,0,[\"updateSearchString\"]],[32,0,[\"searchString\"]]]],null],[2,\"\\n\"],[8,\"paper-content\",[[24,0,\"page-content layout-column\"]],[[\"@padding\"],[false]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-list\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"filteredSites\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"site-item\",[],[[\"@site\",\"@fromGroup\"],[[32,1],true]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\"]}",
    "moduleName": "sentry-advisor/pods/account/sites-group/sites/template.hbs"
  });

  _exports.default = _default;
});