define("sentry-advisor/pods/components/alarm-groups-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OuLBq060",
    "block": "{\"symbols\":[\"alarmGroup\"],\"statements\":[[2,\"\\n\"],[8,\"query-manager-list\",[],[[\"@queryManager\",\"@listModelsLabel\"],[[32,0,[\"queryManager\"]],[30,[36,0],[\"alarm-groups\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[8,\"paper-item\",[],[[],[]],[[\"default\"],[{\"statements\":[[1,[32,1,[\"name\"]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "sentry-advisor/pods/components/alarm-groups-list/template.hbs"
  });

  _exports.default = _default;
});