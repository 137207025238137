define("sentry-advisor/pods/components/sites-map/marker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AIEUai3V",
    "block": "{\"symbols\":[\"layers\",\"MarkerLayer\",\"cluster\",\"MarkerLayer\",\"@site\",\"@selectSite\",\"@layers\",\"@cluster\"],\"statements\":[[6,[37,2],[[32,8]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],[[32,8]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,3,[\"marker\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,4],[],[[\"@lat\",\"@lng\",\"@onClick\",\"@icon\"],[[32,5,[\"coordinates\",\"lat\"]],[32,5,[\"coordinates\",\"lng\"]],[32,6],[30,[36,0],null,[[\"iconUrl\",\"iconSize\",\"className\"],[[32,0,[\"iconUrl\"]],[32,0,[\"iconSize\"]],[32,0,[\"iconClass\"]]]]]]],null],[2,\"\\n\"]],\"parameters\":[4]}]]]],\"parameters\":[3]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[32,7]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,7]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1,[\"marker\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,2],[],[[\"@lat\",\"@lng\",\"@onClick\",\"@icon\"],[[32,5,[\"coordinates\",\"lat\"]],[32,5,[\"coordinates\",\"lng\"]],[32,6],[30,[36,0],null,[[\"iconUrl\",\"iconSize\",\"className\"],[[32,0,[\"iconUrl\"]],[32,0,[\"iconSize\"]],[32,0,[\"iconClass\"]]]]]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"icon\",\"let\",\"if\"]}",
    "moduleName": "sentry-advisor/pods/components/sites-map/marker/template.hbs"
  });

  _exports.default = _default;
});