define("sentry-advisor/pods/components/drop-down-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FqSl8aI9",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0,[\"selected\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[35,0,[\"selected\",\"name\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[32,0,[\"label\"]]],[2,\"\\n  \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"arrow_drop_down\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"select\",\"if\"]}",
    "moduleName": "sentry-advisor/pods/components/drop-down-search/template.hbs"
  });

  _exports.default = _default;
});