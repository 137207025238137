define("ember-composable-helpers/helpers/invoke", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.invoke = invoke;
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var all = Ember.RSVP.all;

  function invoke(_ref) {
    var _ref2 = _toArray(_ref),
        methodName = _ref2[0],
        args = _ref2.slice(1);

    var obj = args.pop();

    if (Ember.isArray(obj)) {
      return function () {
        var promises = obj.map(function (item) {
          var _item$methodName;

          return (_item$methodName = item[methodName]) === null || _item$methodName === void 0 ? void 0 : _item$methodName.call.apply(_item$methodName, [item].concat(_toConsumableArray(args)));
        });
        return all(promises);
      };
    }

    return function () {
      var _obj$methodName;

      return (_obj$methodName = obj[methodName]) === null || _obj$methodName === void 0 ? void 0 : _obj$methodName.call.apply(_obj$methodName, [obj].concat(_toConsumableArray(args)));
    };
  }

  var _default = Ember.Helper.helper(invoke);

  _exports.default = _default;
});