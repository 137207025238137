define("query-manager/components/table/cells/test-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template( /*
                                                          <span data-test-custom-component>{{@value}}</span>
                                                        */{
    "id": "kBYtcpBd",
    "block": "{\"symbols\":[\"@value\"],\"statements\":[[10,\"span\"],[14,\"data-test-custom-component\",\"\"],[12],[1,[32,1]],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "query-manager/components/table/cells/test-cell.hbs"
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});