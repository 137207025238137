define("ember-leaflet/helpers/ember-leaflet-eq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emberLeafletEq = emberLeafletEq;
  _exports.default = void 0;

  function emberLeafletEq(params) {
    return params[0] === params[1];
  }

  var _default = Ember.Helper.helper(emberLeafletEq);

  _exports.default = _default;
});