define("htt-ui/validators/if-less-than", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateLessThan = validateLessThan;
  _exports.validateGreaterThan = validateGreaterThan;

  function validateLessThan(otherAttr)
  /* , options = {} */
  {
    var validator = function validator(_key, newValue, _oldValue, changes, content) {
      // get number and ensure it's an int
      var otherValue = parseInt(changes.hasOwnProperty(otherAttr) ? Ember.get(changes, otherAttr) : Ember.get(content, otherAttr));
      var thisValue = parseInt(newValue);
      if (isNaN(otherValue) || isNaN(thisValue)) return true;
      return newValue < otherValue ? true : "must be less than ".concat(otherAttr);
    };

    return validator;
  }

  function validateGreaterThan(otherAttr) {
    var validator = function validator(_key, newValue, _oldValue, changes, content) {
      // get number and ensure it's an int
      var otherValue = parseInt(changes.hasOwnProperty(otherAttr) ? Ember.get(changes, otherAttr) : Ember.get(content, otherAttr));
      var thisValue = parseInt(newValue);
      if (isNaN(otherValue) || isNaN(thisValue)) return true;
      return newValue > otherValue ? true : "must be greater than ".concat(otherAttr);
    };

    return validator;
  }
});