define("htt-ui/components/ui/list-items/modem", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PaperItem class="md-5-line" @onClick={{@onClick}} data-test-modem>
      <PaperIcon class="material-icons.md-48 md-icon-styles">
          device_hub
      </PaperIcon>
      <div class="md-list-item-text">
          <div class="flex">{{@item.serialNumber}}</div>
          <div class="flex">{{@item.modemType.name}}</div>
          <div class="flex">{{@item.httId}}</div>
      </div>
  </PaperItem>
  */
  {
    "id": "TFXy4qG5",
    "block": "{\"symbols\":[\"@onClick\",\"@item\"],\"statements\":[[8,\"paper-item\",[[24,0,\"md-5-line\"],[24,\"data-test-modem\",\"\"]],[[\"@onClick\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-icon\",[[24,0,\"material-icons.md-48 md-icon-styles\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        device_hub\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"md-list-item-text\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"flex\"],[12],[1,[32,2,[\"serialNumber\"]]],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"flex\"],[12],[1,[32,2,[\"modemType\",\"name\"]]],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"flex\"],[12],[1,[32,2,[\"httId\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "htt-ui/components/ui/list-items/modem.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});