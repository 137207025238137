define("ember-data-cascade-delete/mixins/cascade-delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/no-new-mixins
  var _default = Ember.Mixin.create({
    deleteRecord: function deleteRecord(store, _, snapshot) {
      var recordsToUnload = this.unloadCascadeRecords(store, snapshot.record);
      return this._super.apply(this, arguments).then(function (response) {
        recordsToUnload.forEach(function (childRecord) {
          store.unloadRecord(childRecord);
        });
        return response;
      });
    },
    unloadCascadeRecords: function unloadCascadeRecords(store, record) {
      var _this = this;

      var recordsToUnload = []; // collect all records to unload into recordsToUnload variable

      record.eachRelationship(function (name, descriptor) {
        var options = descriptor.options,
            kind = descriptor.kind;
        var relationshipName = descriptor.key;

        if (options.cascadeDelete && kind === 'hasMany') {
          var hasManyRecordsArray = [];
          var hasManyRecords = record.hasMany(relationshipName).value();

          if (hasManyRecords !== null) {
            hasManyRecordsArray = hasManyRecords.toArray();
          }

          recordsToUnload = recordsToUnload.concat(hasManyRecordsArray);
        }

        if (options.cascadeDelete && kind === 'belongsTo') {
          var belongsToRecords = record.belongsTo(relationshipName).value();

          if (belongsToRecords !== null) {
            recordsToUnload = recordsToUnload.concat([belongsToRecords]);
          }
        }
      });
      var childRecords = recordsToUnload.reduce(function (a, r) {
        return a.concat(_this.unloadCascadeRecords(store, r));
      }, []);
      return recordsToUnload.concat(childRecords);
    }
  });

  _exports.default = _default;
});