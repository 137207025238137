define("sentry-advisor/pods/components/list-items/sentry-advisor-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rFJ7zjdl",
    "block": "{\"symbols\":[\"flag\",\"name\",\"@onClick\",\"@item\"],\"statements\":[[8,\"paper-item\",[],[[\"@class\",\"@onClick\"],[\"list-items-sentry-advisor-message\",[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"md-list-item-text\"],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[2,\"\\n      \"],[1,[32,4,[\"messageType\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"\\n      \"],[1,[32,4,[\"formattedRtuTime\"]]],[2,\" \"],[1,[32,4,[\"formattedLatency\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[10,\"md-chips-wrap\"],[14,0,\"md-chips\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,4,[\"flags\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"md-chip\"],[15,0,[31,[[30,[36,0],[[32,1],\"sa-on-bg-color\",\"sa-off-bg-color\"],null]]]],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"md-chip-content color-white\"],[12],[2,\"\\n              \"],[10,\"span\"],[12],[2,\"\\n                \"],[1,[32,2]],[2,\"\\n              \"],[13],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"-each-in\",\"each\"]}",
    "moduleName": "sentry-advisor/pods/components/list-items/sentry-advisor-message/template.hbs"
  });

  _exports.default = _default;
});