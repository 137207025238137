define("htt-shared-models/models/dashboard-elements/dashboard-element-types", ["exports", "htt-shared-models/models/dashboard-elements/analog-arc-gauge", "htt-shared-models/models/dashboard-elements/analog-round-gauge", "htt-shared-models/models/dashboard-elements/analog-tank", "htt-shared-models/models/dashboard-elements/installation-group", "htt-shared-models/models/dashboard-elements/analog-output", "htt-shared-models/models/dashboard-elements/analog-trend", "htt-shared-models/models/dashboard-elements/counter", "htt-shared-models/models/dashboard-elements/multi-counter", "htt-shared-models/models/dashboard-elements/digital-input", "htt-shared-models/models/dashboard-elements/multi-digital-input", "htt-shared-models/models/dashboard-elements/digital-output", "htt-shared-models/models/dashboard-elements/digital-input-group", "htt-shared-models/models/dashboard-elements/battery-voltage", "htt-shared-models/models/dashboard-elements/generic-group", "htt-shared-models/models/dashboard-elements/group-wrapper", "htt-shared-models/models/dashboard-elements/gas-odor", "htt-shared-models/models/dashboard-elements/text-analog", "htt-shared-models/models/dashboard-elements/pump", "htt-shared-models/models/dashboard-elements/pumps-starts-and-runtime", "htt-shared-models/models/dashboard-elements/rain-counter", "htt-shared-models/models/dashboard-elements/valve", "htt-shared-models/models/dashboard-elements/multi-zone-meter"], function (_exports, _analogArcGauge, _analogRoundGauge, _analogTank, _installationGroup, _analogOutput, _analogTrend, _counter, _multiCounter, _digitalInput, _multiDigitalInput, _digitalOutput, _digitalInputGroup, _batteryVoltage, _genericGroup, _groupWrapper, _gasOdor, _textAnalog, _pump, _pumpsStartsAndRuntime, _rainCounter, _valve, _multiZoneMeter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ChildDashboardElementTypes = _exports.ParentDashboardElementTypes = _exports.WrapperDashboardElementTypes = _exports.default = void 0;
  var DashboardElementTypes = [_installationGroup.DashboardElementsInstallationGroupType, _analogArcGauge.DashboardElementsAnalogArcGaugeType, _analogRoundGauge.DashboardElementsAnalogRoundGaugeType, _analogTank.DashboardElementsAnalogTankType, _analogOutput.DashboardElementsAnalogOutputType, _analogTrend.DashboardElementsAnalogTrendType, _counter.DashboardElementsCounterType, _multiDigitalInput.DashboardElementsMultiDigitalInputType, _digitalInput.DashboardElementsDigitalInputType, _digitalInputGroup.DashboardElementsDigitalInputGroupType, _batteryVoltage.DashboardElementsBatteryVoltageType, _digitalOutput.DashboardElementsDigitalOutputType, _multiCounter.DashboardElementsMultiCounterType, _multiZoneMeter.DashboardElementsMultiZoneMeterType, _genericGroup.DashboardElementsGenericGroupType, _groupWrapper.DashboardElementsGroupWrapperType, _gasOdor.DashboardElementsGasOdorType, _textAnalog.DashboardElementsTextAnalogType, _pump.DashboardElementsPumpType, _pumpsStartsAndRuntime.DashboardElementsPumpsStartsAndRuntimeType, _rainCounter.DashboardElementsRainCounterType, _valve.DashboardElementsValveType];
  var _default = DashboardElementTypes;
  _exports.default = _default;
  var WrapperDashboardElementTypes = DashboardElementTypes.filter(function (de) {
    return de.layer === "wrapper";
  });
  _exports.WrapperDashboardElementTypes = WrapperDashboardElementTypes;
  var ParentDashboardElementTypes = DashboardElementTypes.filter(function (de) {
    return de.layer === "parent";
  });
  _exports.ParentDashboardElementTypes = ParentDashboardElementTypes;
  var ChildDashboardElementTypes = DashboardElementTypes.filter(function (de) {
    return de.layer === "child";
  });
  _exports.ChildDashboardElementTypes = ChildDashboardElementTypes;
});