define("ember-leaflet/helpers/lat-lng-bounds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.latLngBounds = void 0;
  var isFastBoot = typeof FastBoot !== 'undefined';
  /* global L */

  /**
   * Represents a rectangular geographical area on a map by defining two diagonally opposite corners of the rectangle.
   * Instead of two corners, you can also pass in an array of points, and the helper will return the rectangular
   * area that contains all those points.
   *
   * @function latLngBounds
   * @param {LatLng} corner1 first corner
   * @param {LatLng} corner2 second corner
   * @return {LatLngBounds}
   */

  var latLngBounds = isFastBoot ? function () {} : function (latLngs) {
    return L.latLngBounds(latLngs);
  };
  _exports.latLngBounds = latLngBounds;

  var _default = Ember.Helper.helper(latLngBounds);

  _exports.default = _default;
});