define("sentry-advisor/pods/account/notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NDo1ZPBj",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"app-bars/account\",[],[[\"@account\",\"@title\"],[[32,0,[\"account\"]],[30,[36,0],[\"notes\"],null]]],null],[2,\"\\n\\n\"],[8,\"query-manager-view\",[],[[\"@listView\",\"@onItemClick\",\"@queryManager\",\"@columns\",\"@filterComponent\",\"@listItemComponent\"],[[32,0,[\"globals\",\"isSmall\"]],[32,0,[\"onLogClick\"]],[32,1],[32,0,[\"columns\"]],\"logs-filters\",\"ui/list-items/log\"]],null],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\"]}",
    "moduleName": "sentry-advisor/pods/account/notes/template.hbs"
  });

  _exports.default = _default;
});