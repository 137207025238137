define("sentry-advisor/pods/components/sentry-advisor-model-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xioAFQqx",
    "block": "{\"symbols\":[\"sentryAdvisorModel\"],\"statements\":[[2,\"\\n\"],[8,\"paper-select\",[[16,0,[31,[\"flex \",[30,[36,1],[[35,0],\"default-highlight-label\"],null]]]]],[[\"@placeholder\",\"@label\",\"@selected\",\"@searchPlaceholder\",\"@loadingMessage\",\"@noMatchesMessage\",\"@searchEnabled\",\"@options\",\"@searchField\",\"@closeOnSelect\",\"@errors\",\"@isTouched\",\"@onChange\"],[[32,0,[\"label\"]],[32,0,[\"label\"]],[32,0,[\"selected\"]],[30,[36,3],[\"Search for a \",[30,[36,2],[\"sentry-advisor-model\"],null]],null],[30,[36,2],[\"searchingItem\"],[[\"item\"],[[30,[36,2],[\"sentry-advisor-models\"],null]]]],[30,[36,2],[\"no-item\"],[[\"item\"],[[30,[36,2],[\"sentry-advisor-model\"],null]]]],true,[32,0,[\"sentryAdvisorModels\"]],\"name\",true,[32,0,[\"errors\"]],[32,0,[\"isTouched\"]],[32,0,[\"onChange\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"defaultHighlightLabel\",\"if\",\"t\",\"concat\"]}",
    "moduleName": "sentry-advisor/pods/components/sentry-advisor-model-selector/template.hbs"
  });

  _exports.default = _default;
});