define("htt-ui/components/ui/list-items/rtu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PaperItem class="md-5-line" @onClick={{@onClick}} data-test-rtu>
      <PaperIcon class="material-icons.md-48 md-icon-styles">
          device_hub
      </PaperIcon>
      <div class="md-list-item-text">
          {{#if @item.installation}}
              <div class="flex">{{@item.installation.name}}</div>
          {{/if}}
          <div class="flex">{{@item.stackup.httId}}</div>
          <div class="flex">{{@item.macAddress}}</div>
          <div class="flex">{{@item.stackup.modemSerialNumber}}</div>
          <div class="flex">{{@item.stackup.modemType.name}}</div>
          <div class="flex">{{@item.stackup.stackupType.name}}</div>
          <div class="flex">{{@item.stackup.codeVersion.name}}</div>
          <div class="flex">{{@item.rtuType.name}}</div>
      </div>
  </PaperItem>
  */
  {
    "id": "Pkk/DKpF",
    "block": "{\"symbols\":[\"@item\",\"@onClick\"],\"statements\":[[8,\"paper-item\",[[24,0,\"md-5-line\"],[24,\"data-test-rtu\",\"\"]],[[\"@onClick\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-icon\",[[24,0,\"material-icons.md-48 md-icon-styles\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        device_hub\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"md-list-item-text\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"installation\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"flex\"],[12],[1,[32,1,[\"installation\",\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[10,\"div\"],[14,0,\"flex\"],[12],[1,[32,1,[\"stackup\",\"httId\"]]],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"flex\"],[12],[1,[32,1,[\"macAddress\"]]],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"flex\"],[12],[1,[32,1,[\"stackup\",\"modemSerialNumber\"]]],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"flex\"],[12],[1,[32,1,[\"stackup\",\"modemType\",\"name\"]]],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"flex\"],[12],[1,[32,1,[\"stackup\",\"stackupType\",\"name\"]]],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"flex\"],[12],[1,[32,1,[\"stackup\",\"codeVersion\",\"name\"]]],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"flex\"],[12],[1,[32,1,[\"rtuType\",\"name\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "htt-ui/components/ui/list-items/rtu.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});