define("ember-engines/-private/engine-ext", ["require", "ember-engines/components/link-to-external"], function (_require, _linkToExternal) {
  "use strict";

  Ember.Engine.reopen({
    buildRegistry: function buildRegistry() {
      var registry = this._super.apply(this, arguments);

      if (!(this instanceof Ember.Application)) {
        if (!true) {
          var EngineScopedLinkComponent = (0, _require.default)("ember-engines/components/link-to-component").default;
          registry.register('component:link-to', EngineScopedLinkComponent);
        }

        registry.register('component:link-to-external', _linkToExternal.default);
      }

      return registry;
    }
  });
});