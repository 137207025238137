define("sentry-advisor/pods/components/reports/info-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qXBGD39f",
    "block": "{\"symbols\":[\"card\",\"title\",\"text\",\"&attrs\",\"@title\",\"@description\",\"@onSubmit\"],\"statements\":[[11,\"div\"],[24,0,\"flex-xs flex-gt-xs-50 flex-gt-md-33\"],[17,4],[12],[2,\"\\n\"],[2,\"  \"],[8,\"paper-card\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"text\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,3,[\"headline\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,5]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[3]}]]],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n        \\n    \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"p\"],[12],[1,[32,6]],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1,[\"actions\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"paper-button\",[[24,0,\"flex\"]],[[\"@primary\",\"@onClick\"],[true,[32,7]]],[[\"default\"],[{\"statements\":[[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"mail\"]],\"parameters\":[]}]]],[2,\" Email Report\"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "sentry-advisor/pods/components/reports/info-card/template.hbs"
  });

  _exports.default = _default;
});