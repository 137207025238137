define("htt-ui/components/ui/buttons/fab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PaperButton
    @raised={{true}}
    @fab={{true}}
    @class="green-btn bottom-right-btn absolute-position"
    @onClick={{@onClick}}
    ...attributes
    data-test-floating-action-button
  >
    <PaperIcon>
      {{or @icon 'add'}}
    </PaperIcon>
  </PaperButton>
  */
  {
    "id": "t3zKNYJv",
    "block": "{\"symbols\":[\"@onClick\",\"&attrs\",\"@icon\"],\"statements\":[[8,\"paper-button\",[[17,2],[24,\"data-test-floating-action-button\",\"\"]],[[\"@raised\",\"@fab\",\"@class\",\"@onClick\"],[true,true,\"green-btn bottom-right-btn absolute-position\",[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[[32,3],\"add\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"or\"]}",
    "moduleName": "htt-ui/components/ui/buttons/fab.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});