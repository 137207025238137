define("sentry-advisor/pods/components/address-menu-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vkMtKvN/",
    "block": "{\"symbols\":[\"content\"],\"statements\":[[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"content\"]]],null,[[\"default\"],[{\"statements\":[[8,[32,1,[\"menu-item\"]],[],[[\"@onClick\"],[[30,[36,1],[[32,0],\"openAddressForm\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"exit_to_app\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"span\"],[12],[1,[30,[36,2],[\"address\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[8,\"address-dialog\",[],[[\"@address\",\"@onCancel\",\"@onSave\"],[[34,0],[30,[36,1],[[32,0],\"clossAddressForm\"],null],[30,[36,1],[[32,0],\"clossAddressForm\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"address\",\"action\",\"t\",\"with\",\"addressFormOpen\",\"if\"]}",
    "moduleName": "sentry-advisor/pods/components/address-menu-item/template.hbs"
  });

  _exports.default = _default;
});