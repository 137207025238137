define("sentry-advisor/pods/components/site-alarm-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tA3xCGNJ",
    "block": "{\"symbols\":[\"@site\"],\"statements\":[[10,\"div\"],[14,0,\"info-card\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"offline\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"paper-item\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"span\"],[12],[8,\"paper-icon\",[[24,0,\"sa-status-icon sa-offline-color\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"wifi_off\"]],\"parameters\":[]}]]],[2,\" Offline\"],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[8,\"site-high-level-alarm-status\",[],[[\"@site\"],[[32,1]]],null],[2,\"\\n    \"],[8,\"site-external-power-status\",[],[[\"@site\"],[[32,1]]],null],[2,\"\\n\"],[6,[37,0],[[32,1,[\"sentryAdvisorSite\",\"advancedMonitorPanel\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"site-modbus-alarm-status\",[],[[\"@site\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "sentry-advisor/pods/components/site-alarm-status/template.hbs"
  });

  _exports.default = _default;
});