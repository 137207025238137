define("sentry-advisor/engine", ["exports", "ember-engines/engine", "ember-load-initializers", "sentry-advisor/config/environment", "sentry-advisor/resolver"], function (_exports, _engine, _emberLoadInitializers, _environment, _resolver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable */
  var modulePrefix = _environment.default.modulePrefix;

  var Eng = _engine.default.extend({
    modulePrefix: modulePrefix,
    podModulePrefix: 'sentry-advisor/pods',
    Resolver: _resolver.default,
    dependencies: {
      externalRoutes: ['home'],
      services: ['alarmToaster', 'audience', 'event-service', 'faye', 'fetch', 'flashMessages', 'globals', 'httSession', 'intl', 'paperToaster', 'right-side-bar', "right-side-bar-locked", 'router', 'store', 'time-formats', 'timezone-service', 'tos-agreement']
    }
  });

  (0, _emberLoadInitializers.default)(Eng, modulePrefix);
  var _default = Eng;
  _exports.default = _default;
});