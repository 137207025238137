define("htt-ui/components/paper-addons/paper-toaster", ["exports", "ember-paper/components/paper-toaster"], function (_exports, _paperToaster) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  {{#if activeToast.show}}
    {{#paper-toast
      onClose=(action onClose activeToast)
      position=activeToast.position
      duration=activeToast.duration
      class=activeToast.toastClass
      _destroyMessage=_destroyMessage
      parent=parent as |toast|}}
      {{#if activeToast.installationAlarm}}
        {{alarm-toast toast=activeToast}}
      {{else if activeToast.componentName}}
        {{component activeToast.componentName}}
      {{else}}
        {{#toast.text}}{{activeToast.text}}{{/toast.text}}
        {{#if activeToast.action}}
          {{#paper-button accent=activeToast.action.accent onClick=activeToast.action.onClick}}
            {{activeToast.action.label}}
          {{/paper-button}}
        {{/if}}
      {{/if}}
    {{/paper-toast}}
  {{/if}}
  
  */
  {
    "id": "c7VyO/8N",
    "block": "{\"symbols\":[\"toast\",\"&default\"],\"statements\":[[18,2,null],[2,\"\\n\"],[6,[37,3],[[35,0,[\"show\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,9],null,[[\"onClose\",\"position\",\"duration\",\"class\",\"_destroyMessage\",\"parent\"],[[30,[36,8],[[32,0],[35,7],[35,0]],null],[35,0,[\"position\"]],[35,0,[\"duration\"]],[35,0,[\"toastClass\"]],[35,6],[35,5]]],[[\"default\"],[{\"statements\":[[6,[37,3],[[35,0,[\"installationAlarm\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,4],null,[[\"toast\"],[[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,0,[\"componentName\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,2],[[35,0,[\"componentName\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[6,[37,2],[[32,1,[\"text\"]]],null,[[\"default\"],[{\"statements\":[[1,[35,0,[\"text\"]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[35,0,[\"action\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"accent\",\"onClick\"],[[35,0,[\"action\",\"accent\"]],[35,0,[\"action\",\"onClick\"]]]],[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[35,0,[\"action\",\"label\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"activeToast\",\"paper-button\",\"component\",\"if\",\"alarm-toast\",\"parent\",\"_destroyMessage\",\"onClose\",\"action\",\"paper-toast\"]}",
    "moduleName": "htt-ui/components/paper-addons/paper-toaster.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _paperToaster.default.extend({
    _destroyMessage: function _destroyMessage() {
      if (!this.isDestroyed && this.duration !== false) {
        this.onClose();
      }
    }
  }));

  _exports.default = _default;
});