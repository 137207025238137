define("sentry-advisor/pods/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6jr15C8K",
    "block": "{\"symbols\":[\"toolbar\"],\"statements\":[[8,\"paper-toolbar\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"tools\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"loading\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[8,\"paper-content\",[[24,0,\"page-content layout-align-center-center flex-grow layout-column\"]],[[\"@padding\"],[false]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-progress-circular\",[],[[\"@primary\",\"@diameter\"],[true,96]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "sentry-advisor/pods/loading/template.hbs"
  });

  _exports.default = _default;
});