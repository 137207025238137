define("sentry-advisor/pods/components/ui/cells/sentry-advisor-site-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NudaxfWo",
    "block": "{\"symbols\":[\"@value\"],\"statements\":[[2,\" \"],[8,\"link-to\",[],[[\"@route\",\"@models\"],[\"account.sites.site\",[30,[36,0],[[32,1,[\"customer\",\"id\"]],[32,1,[\"id\"]]],null]]],[[\"default\"],[{\"statements\":[[1,[32,1,[\"name\"]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"array\"]}",
    "moduleName": "sentry-advisor/pods/components/ui/cells/sentry-advisor-site-link/template.hbs"
  });

  _exports.default = _default;
});