define('ember-paper-stepper/components/paper-step-actions', ['exports', 'ember-paper-stepper/templates/components/paper-step-actions'], function (exports, _paperStepActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _paperStepActions.default,
    tagName: 'md-step-actions'
  });
});