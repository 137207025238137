define('ember-paper-stepper/components/paper-step-body', ['exports', 'ember-paper-stepper/templates/components/paper-step-body'], function (exports, _paperStepBody) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _paperStepBody.default,
    tagName: 'md-step-body',
    classNames: ['flex'],
    classNameBindings: ['vertical::md-padding']
  });
});