define("sentry-advisor/pods/components/site-high-level-alarm-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "368npywY",
    "block": "{\"symbols\":[],\"statements\":[[8,\"paper-item\",[],[[\"@class\"],[[30,[36,1],[[35,0],\"sa-in-alarm-color\",\"sa-not-in-alarm-color\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[12],[8,\"paper-icon\",[[24,0,\"sa-status-icon\"]],[[],[]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[[35,0],\"error\",\"check_circle\"],null]]],\"parameters\":[]}]]],[1,[34,2]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"inHighLevelAlarm\",\"if\",\"statusText\"]}",
    "moduleName": "sentry-advisor/pods/components/site-high-level-alarm-status/template.hbs"
  });

  _exports.default = _default;
});