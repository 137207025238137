define("sentry-advisor/pods/components/dialogs/user/email-addresses/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EoLxFNqj",
    "block": "{\"symbols\":[\"email\",\"@user\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,2,[\"emailAddresses\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"dialogs/user/email-addresses/item\",[],[[\"@email\",\"@canEdit\",\"@canDelete\"],[[32,1],[30,[36,1],[\"editEmailAddress user\",null],[[\"user\"],[[32,2]]]],[30,[36,1],[\"deleteEmailAddress user\",null],[[\"user\"],[[32,2]]]]]],null],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"md-margin-left\"],[14,5,\"font-style: italic\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"no-item\"],[[\"item\"],[[30,[36,0],[\"email-addresses\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[30,[36,1],[\"deleteEmailAddress user\",null],[[\"user\"],[[32,2]]]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"paper-button\",[],[[\"@label\",\"@primary\",\"@disabled\",\"@onClick\"],[[30,[36,0],[\"addItem\"],[[\"item\"],[[30,[36,0],[\"email-address\"],null]]]],true,[32,0,[\"disableAdd\"]],[32,0,[\"add\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"can\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "sentry-advisor/pods/components/dialogs/user/email-addresses/template.hbs"
  });

  _exports.default = _default;
});