define("sentry-advisor/pods/account/sentry-advisors/sentry-advisor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cdb2V+Of",
    "block": "{\"symbols\":[],\"statements\":[[8,\"dialogs/sentry-advisor\",[],[[\"@sentry\",\"@onClose\",\"@onCancel\"],[[32,0,[\"model\"]],[32,0,[\"onClose\"]],[32,0,[\"onCancel\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "sentry-advisor/pods/account/sentry-advisors/sentry-advisor/template.hbs"
  });

  _exports.default = _default;
});