define("htt-ui/components/ui/cells/avatar-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PaperIcon class="ui-cell-avatar-icon" data-test-cell-avatar-icon>
    {{@column.icon}}
  </PaperIcon>
  */
  {
    "id": "allRbtzx",
    "block": "{\"symbols\":[\"@column\"],\"statements\":[[8,\"paper-icon\",[[24,0,\"ui-cell-avatar-icon\"],[24,\"data-test-cell-avatar-icon\",\"\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[32,1,[\"icon\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "htt-ui/components/ui/cells/avatar-icon.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});