define("sentry-advisor/pods/components/sentry-advisor-speed-dial/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iumHkhHE",
    "block": "{\"symbols\":[\"dial\",\"actions\",\"@onUpload\",\"@onCreate\"],\"statements\":[[8,\"paper-speed-dial\",[],[[\"@onMouseEnter\",\"@onMouseLeave\",\"@open\",\"@onToggle\",\"@direction\",\"@class\"],[[30,[36,1],[[30,[36,0],[[32,0,[\"open\"]]],null],true],null],[30,[36,1],[[30,[36,0],[[32,0,[\"open\"]]],null],false],null],[32,0,[\"open\"]],[30,[36,1],[[30,[36,0],[[32,0,[\"open\"]]],null]],null],\"up\",\"bottom-right-btn absolute-position\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"actions\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"action\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"paper-button\",[],[[\"@mini\",\"@onClick\"],[true,[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          cloud_upload\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"paper-tooltip\",[],[[\"@position\"],[\"top\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,2],[\"bulk-create\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n  \"],[8,[32,1,[\"trigger\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-button\",[],[[\"@raised\",\"@fab\",\"@class\",\"@onClick\"],[true,true,\"green-btn\",[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        add\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"t\"]}",
    "moduleName": "sentry-advisor/pods/components/sentry-advisor-speed-dial/template.hbs"
  });

  _exports.default = _default;
});