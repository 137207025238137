define("htt-ui/components/ui/list-items/telemetry-data-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PaperItem @onClick={{@onClick}} @class="md-3-line" data-test-telemetry-data-item>
    <div class="md-list-item-text">
      <h3><strong>{{@item.thingId}}</strong></h3>
  
      <p><strong>Generated</strong>: {{@item.formattedGeneratedTime}}</p>
      <p><strong>Received</strong>: {{@item.formattedInsertTime}}</p>
  
      {{#if @item.processedAt}}
        <p><strong>Processed</strong>: {{@item.formattedProcessedTime}}</p>
      {{/if}}
  
      {{#if @item.failedAt}}
        <p><strong>Failed</strong>: {{@item.formattedFailedTime}}</p>
      {{/if}}
    </div>
  </PaperItem>
  
  */
  {
    "id": "ZAHXQhU2",
    "block": "{\"symbols\":[\"@item\",\"@onClick\"],\"statements\":[[8,\"paper-item\",[[24,\"data-test-telemetry-data-item\",\"\"]],[[\"@onClick\",\"@class\"],[[32,2],\"md-3-line\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"md-list-item-text\"],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[10,\"strong\"],[12],[1,[32,1,[\"thingId\"]]],[13],[13],[2,\"\\n\\n    \"],[10,\"p\"],[12],[10,\"strong\"],[12],[2,\"Generated\"],[13],[2,\": \"],[1,[32,1,[\"formattedGeneratedTime\"]]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[10,\"strong\"],[12],[2,\"Received\"],[13],[2,\": \"],[1,[32,1,[\"formattedInsertTime\"]]],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,1,[\"processedAt\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[12],[10,\"strong\"],[12],[2,\"Processed\"],[13],[2,\": \"],[1,[32,1,[\"formattedProcessedTime\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[32,1,[\"failedAt\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[12],[10,\"strong\"],[12],[2,\"Failed\"],[13],[2,\": \"],[1,[32,1,[\"formattedFailedTime\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "htt-ui/components/ui/list-items/telemetry-data-item.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});