define("htt-shared-models/models/site-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Object
   */

  /**
   * Site message object
   * @class Object.site-message
   */
  var _default = Ember.Object.extend({
    // ---------- properties ----------

    /**
     * Site message to be displayed.
     * @property text
     * @type string
     */

    /**
     * Type of site-message. Used for color of the site message div.
     * @property type
     * @type string
     */

    /**
     * Error object for site-message.
     * @property errors
     * @type object
     */
    text: null,
    type: null,
    errors: null,
    intl: Ember.inject.service(),
    init: function init() {
      this.set("errors", Ember.Object.create());
      this.validateText();
    },
    // ---------- observers ----------

    /**
     * Method that sets errors if requirements are not set.
     * @method validateText (observer)
     */
    // eslint-disable-next-line ember/no-observers
    validateText: Ember.observer("text", function () {
      if (Ember.isBlank(this.text)) {
        this.set("errors.text", [this.intl.t("mustBeSet")]);
      } else {
        this.set("errors.text", []);
      }
    })
  });

  _exports.default = _default;
});