define("sentry-advisor/pods/components/site-external-power-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BhERTSEO",
    "block": "{\"symbols\":[],\"statements\":[[8,\"paper-item\",[],[[\"@class\"],[[30,[36,0],[[32,0,[\"inAlarm\"]],\"sa-in-alarm-color\",\"sa-not-in-alarm-color\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[12],[8,\"paper-icon\",[[24,0,\"sa-status-icon\"]],[[],[]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[[32,0,[\"inAlarm\"]],\"power_off\",\"power\"],null]]],\"parameters\":[]}]]],[1,[30,[36,1],[\"ac-power\"],null]],[2,\" \"],[1,[32,0,[\"statusText\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"t\"]}",
    "moduleName": "sentry-advisor/pods/components/site-external-power-status/template.hbs"
  });

  _exports.default = _default;
});