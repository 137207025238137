define("sentry-advisor/pods/components/charts/chart-wrapper/selectors/year-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bEsNSSpT",
    "block": "{\"symbols\":[\"year\"],\"statements\":[[6,[37,5],null,[[\"class\",\"options\",\"selected\",\"onChange\"],[\"flex yearSelect\",[30,[36,4],null,[[\"start\",\"end\",\"step\"],[[35,3],[35,2],-1]]],[35,1],[35,0]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"changeEndYear\",\"endDate\",\"end\",\"start\",\"range\",\"paper-select\"]}",
    "moduleName": "sentry-advisor/pods/components/charts/chart-wrapper/selectors/year-picker/template.hbs"
  });

  _exports.default = _default;
});