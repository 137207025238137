define("sentry-advisor/pods/components/distributor-customer-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kKgdEIqs",
    "block": "{\"symbols\":[\"owner\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"layout-row layout-align-start-center\"],[12],[2,\"\\n  \"],[8,\"paper-select\",[[24,0,\"flex-grow\"]],[[\"@placeholder\",\"@selected\",\"@options\",\"@searchField\",\"@searchPlaceholder\",\"@searchEnabled\",\"@closeOnSelect\",\"@errors\",\"@isTouched\",\"@onChange\"],[[30,[36,0],[\"owner\"],null],[32,0,[\"selected\"]],[32,0,[\"options\"]],\"name\",[30,[36,0],[\"search\"],null],\"true\",\"true\",[32,0,[\"errors\"]],[32,0,[\"isTouched\"]],[32,0,[\"onChange\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,2,[[32,1]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "sentry-advisor/pods/components/distributor-customer-selector/template.hbs"
  });

  _exports.default = _default;
});