define("htt-ui/components/dialogs/technical-support-log-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PaperDialog @fullscreen={{true}} @class="flex-75">
    <Forms::TechnicalSupportLog
      @log={{@log}}
      @onCancel={{@onCancel}}
      @onSave={{@onSave}} as |form|
    >
      <PaperToolbar as |toolbar|>
        <toolbar.tools>
          <PaperButton @iconButton={{true}} @onClick={{form.cancel}}>
            <PaperIcon>
              close
            </PaperIcon>
          </PaperButton>
          <h3>
            Technical Support Log
          </h3>
        </toolbar.tools>
      </PaperToolbar>
      <PaperDialogContent data-test-technical-support-log-form-dialog>
        <form.body @hideInstallationSelect={{@hideInstallationSelect}} />
      </PaperDialogContent>
      <PaperDialogActions>
        <FormSaveCancelBtns @onCancel={{form.cancel}} @onSubmit={{form.save}} />
      </PaperDialogActions>
    </Forms::TechnicalSupportLog>
  </PaperDialog>
  */
  {
    "id": "F7BQjlcu",
    "block": "{\"symbols\":[\"form\",\"toolbar\",\"@log\",\"@onCancel\",\"@onSave\",\"@hideInstallationSelect\"],\"statements\":[[8,\"paper-dialog\",[],[[\"@fullscreen\",\"@class\"],[true,\"flex-75\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"forms/technical-support-log\",[],[[\"@log\",\"@onCancel\",\"@onSave\"],[[32,3],[32,4],[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-toolbar\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"tools\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"paper-button\",[],[[\"@iconButton\",\"@onClick\"],[true,[32,1,[\"cancel\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            close\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[10,\"h3\"],[12],[2,\"\\n          Technical Support Log\\n        \"],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n    \"],[8,\"paper-dialog-content\",[[24,\"data-test-technical-support-log-form-dialog\",\"\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1,[\"body\"]],[],[[\"@hideInstallationSelect\"],[[32,6]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"paper-dialog-actions\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"form-save-cancel-btns\",[],[[\"@onCancel\",\"@onSubmit\"],[[32,1,[\"cancel\"]],[32,1,[\"save\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "htt-ui/components/dialogs/technical-support-log-form.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});