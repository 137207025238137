define("sentry-advisor/pods/components/mass-decommission-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "15o/6N8/",
    "block": "{\"symbols\":[],\"statements\":[[8,\"paper-dialog\",[],[[\"@class\",\"@fullscreen\"],[\"flex-gt-sm-50\",true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"paper-toolbar\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-toolbar-tools\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"h2\"],[14,0,\"md-title\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"mass-decommission\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"flex\"],[12],[13],[2,\"\\n\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"paper-dialog-content\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"are-you-sure-you-want-to-action-item\"],[[\"action\",\"item\"],[[30,[36,0],[\"decommission\"],null],[30,[36,0],[\"sentry-advisor\"],[[\"count\"],[[35,1,[\"length\"]]]]]]]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"paper-dialog-actions\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[14,0,\"flex\"],[12],[13],[2,\"\\n    \"],[8,\"paper-button\",[],[[\"@onClick\"],[[32,0,[\"onCancel\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"no\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"paper-button\",[],[[\"@primary\",\"@onClick\"],[true,[32,0,[\"onSave\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"yes\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"sentries\"]}",
    "moduleName": "sentry-advisor/pods/components/mass-decommission-form/template.hbs"
  });

  _exports.default = _default;
});