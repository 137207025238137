define("sentry-advisor/pods/account-group/sub-account-groups/account-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1CGoPiqi",
    "block": "{\"symbols\":[],\"statements\":[[8,\"dialogs/account-group\",[],[[\"@distributor\",\"@onCancel\",\"@onClose\"],[[32,0,[\"model\"]],[30,[36,0],[\"account-group.sub-account-groups\"],null],[32,0,[\"afterDelete\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"transition-to\"]}",
    "moduleName": "sentry-advisor/pods/account-group/sub-account-groups/account-group/template.hbs"
  });

  _exports.default = _default;
});