define("ember-paper-flatpickr/components/paper-flatpickr", ["exports", "ember-paper-flatpickr/templates/components/paper-flatpickr", "ember-invoke-action"], function (_exports, _paperFlatpickr, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _paperFlatpickr.default,
    input: null,
    tagName: "",
    actions: {
      registerInput: function registerInput(input) {
        this.set('input', input);
      },
      unregisterInput: function unregisterInput() {
        this.set('input', null);
      },
      clear: function clear() {
        (0, _emberInvokeAction.invokeAction)(this.input, 'onChange', null);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.set('input', null);
    }
  });

  _exports.default = _default;
});