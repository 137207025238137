define("sentry-advisor/pods/components/dialogs/user/forms/delete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WRSngeff",
    "block": "{\"symbols\":[],\"statements\":[[8,\"paper-dialog-toolbar\",[],[[\"@title\"],[[30,[36,0],[\"delete-item\"],[[\"item\"],[[30,[36,0],[\"user.one\"],null]]]]]],null],[2,\"\\n\\n\"],[8,\"paper-dialog-content\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"pending-block\",[],[[\"@pending\"],[[32,0,[\"pending\"]]]],null],[2,\"\\n  \"],[8,\"error-block\",[],[[\"@error\"],[[32,0,[\"error\"]]]],null],[2,\"\\n    \"],[1,[30,[36,0],[\"areYouSureYouWantToDelete\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"paper-dialog-actions\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"span\"],[14,0,\"flex\"],[12],[13],[2,\"\\n  \"],[8,\"paper-button\",[],[[\"@onClick\",\"@disabled\"],[[32,0,[\"onCancel\"]],[32,0,[\"pending\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"no\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"paper-button\",[],[[\"@primary\",\"@onClick\",\"@disabled\"],[true,[32,0,[\"onSave\"]],[32,0,[\"pending\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"yes\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "sentry-advisor/pods/components/dialogs/user/forms/delete/template.hbs"
  });

  _exports.default = _default;
});