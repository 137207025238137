define("sentry-advisor/pods/components/dialogs/sentry-advisor-model/edit-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oVtEKJYY",
    "block": "{\"symbols\":[],\"statements\":[[2,\" \"],[8,\"paper-dialog-content\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"layout-column\"],[12],[2,\"\\n        \"],[8,\"paper-input\",[],[[\"@label\",\"@value\",\"@isTouched\",\"@onChange\",\"@errors\"],[[30,[36,0],[\"name\"],null],[32,0,[\"changeset\",\"name\"]],[32,0,[\"isTouched\"]],[30,[36,3],[[32,0],[30,[36,2],[[35,1,[\"name\"]]],null]],null],[32,0,[\"changeset\",\"error\",\"name\",\"validation\"]]]],null],[2,\"\\n        \"],[8,\"paper-input\",[],[[\"@label\",\"@isTouched\",\"@value\",\"@onChange\"],[[30,[36,0],[\"service-provider\"],null],[32,0,[\"isTouched\"]],[32,0,[\"changeset\",\"serviceProvider\"]],[30,[36,3],[[32,0],[30,[36,2],[[35,1,[\"serviceProvider\"]]],null]],null]]],null],[2,\"\\n        \"],[8,\"paper-input\",[],[[\"@label\",\"@isTouched\",\"@value\",\"@onChange\"],[[30,[36,0],[\"description\"],null],[32,0,[\"isTouched\"]],[32,0,[\"changeset\",\"description\"]],[30,[36,3],[[32,0],[30,[36,2],[[35,1,[\"description\"]]],null]],null]]],null],[2,\"\\n      \"],[13],[2,\"\\n\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"paper-dialog-actions\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"span\"],[14,0,\"flex\"],[12],[13],[2,\"\\n      \"],[8,\"paper-button\",[],[[\"@onClick\"],[[32,0,[\"onCancel\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"cancel\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"paper-button\",[],[[\"@primary\",\"@disabled\",\"@onClick\"],[true,[32,0,[\"changeset\",\"isInvalid\"]],[32,0,[\"onSave\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"save\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"changeset\",\"mut\",\"action\"]}",
    "moduleName": "sentry-advisor/pods/components/dialogs/sentry-advisor-model/edit-form/template.hbs"
  });

  _exports.default = _default;
});