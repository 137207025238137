define("htt-shared-models/models/htt-controls/triggers/trigger_types", ["exports", "htt-shared-models/models/htt-controls/triggers/analog-behind-threshold", "htt-shared-models/models/htt-controls/triggers/analog-exceed-threshold", "htt-shared-models/models/htt-controls/triggers/analog-reading", "htt-shared-models/models/htt-controls/triggers/digital-input-alarm-clear", "htt-shared-models/models/htt-controls/triggers/digital-input-alarm-set", "htt-shared-models/models/htt-controls/triggers/digital-input-status-close", "htt-shared-models/models/htt-controls/triggers/digital-input-status-open", "htt-shared-models/models/htt-controls/triggers/digital-output-state", "htt-shared-models/models/htt-controls/triggers/get-group-state", "htt-shared-models/models/htt-controls/triggers/rain-counter-reading", "htt-shared-models/models/htt-controls/triggers/time-interval", "htt-shared-models/models/htt-controls/triggers/analog-output-reading", "htt-shared-models/models/htt-controls/triggers/counter-value"], function (_exports, _analogBehindThreshold, _analogExceedThreshold, _analogReading, _digitalInputAlarmClear, _digitalInputAlarmSet, _digitalInputStatusClose, _digitalInputStatusOpen, _digitalOutputState, _getGroupState, _rainCounterReading, _timeInterval, _analogOutputReading, _counterValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TriggerTypes = void 0;
  var TriggerTypes = [_analogBehindThreshold.HttControlsTriggersAnalogBehindThresholdType, _analogExceedThreshold.HttControlsTriggersAnalogExceedThresholdType, _analogReading.HttControlsTriggersAnalogReadingType, _analogOutputReading.HttControlsTriggersAnalogOutputReadingType, _counterValue.HttControlsTriggersCounterValueType, _digitalInputAlarmClear.HttControlsTriggersDigitalInputAlarmClearType, _digitalInputStatusClose.HttControlsTriggersDigitalInputStatusCloseType, _digitalInputAlarmSet.HttControlsTriggersDigitalInputAlarmSetType, _digitalInputStatusOpen.HttControlsTriggersDigitalInputStatusOpenType, _digitalOutputState.HttControlsTriggersDigitalOutputStateType, _getGroupState.HttControlsTriggersGetGroupStatusType, _rainCounterReading.HttControlsTriggersRainCounterReadingType, _timeInterval.HttControlsTriggersTimeIntervalType];
  _exports.TriggerTypes = TriggerTypes;
  var _default = TriggerTypes;
  _exports.default = _default;
});