define("ember-href-to/helpers/href-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hrefTo = hrefTo;
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function hrefTo(context, params) {
    var routing = Ember.getOwner(context).lookup("service:-routing");
    return routing.generateURL.apply(routing, _toConsumableArray(getParamsForGenerateURL(params)));
  }

  function getParamsForGenerateURL(params) {
    params = params.slice(); // create a copy

    var targetRouteName = params.shift(); // the first param is always the target route name

    var lastParam = params[params.length - 1]; // the last param might be queryParams

    var queryParams;

    if (lastParam && lastParam.isQueryParams) {
      queryParams = params.pop().values;
    } else {
      queryParams = {};
    }

    var models = params; // the remainder are the models

    return [targetRouteName, models, queryParams];
  }

  var _default = Ember.Helper.extend({
    compute: function compute(params, namedArgs) {
      if (namedArgs.params) {
        return hrefTo(this, namedArgs.params);
      } else {
        return hrefTo(this, params);
      }
    }
  });

  _exports.default = _default;
});