define("sentry-advisor/pods/components/dialogs/alarm/acknowledge/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F9AKdmVj",
    "block": "{\"symbols\":[\"@alarm\"],\"statements\":[[6,[37,4],[[30,[36,3],[[35,1,[\"active\"]],[30,[36,2],[[35,1,[\"acknowledged\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"   \"],[8,\"alarm-acknowledge-button\",[],[[\"@alarm\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n       \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"check\"]],\"parameters\":[]}]]],[2,\"\\n       \"],[1,[30,[36,0],[\"acknowledge\"],null]],[2,\"\\n   \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"paper-item\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"span\"],[12],[2,\"\\n            \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"person\"]],\"parameters\":[]}]]],[2,\"\\n            \"],[1,[32,0,[\"acknowledgeText\"]]],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"alarm\",\"not\",\"and\",\"if\"]}",
    "moduleName": "sentry-advisor/pods/components/dialogs/alarm/acknowledge/template.hbs"
  });

  _exports.default = _default;
});