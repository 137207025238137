define("sentry-advisor/pods/components/site-upload-menu-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OicfX1Il",
    "block": "{\"symbols\":[\"content\"],\"statements\":[[6,[37,1],[[32,0,[\"content\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,[32,1,[\"menu-item\"]],[],[[\"@onClick\"],[[34,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"cloud_upload\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"Bulk Site Upload\"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"onClick\",\"with\"]}",
    "moduleName": "sentry-advisor/pods/components/site-upload-menu-item/template.hbs"
  });

  _exports.default = _default;
});