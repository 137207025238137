define("sentry-advisor/pods/components/sentry-advisor-messages-view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "laPgDQ2b",
    "block": "{\"symbols\":[\"@site\"],\"statements\":[[8,\"query-manager-view\",[[24,0,\"sentry-advisor-messages\"]],[[\"@listView\",\"@sizeConstraints\",\"@columns\",\"@queryManager\",\"@onItemClick\",\"@filterComponent\",\"@listItemComponent\",\"@hideSearch\"],[[32,0,[\"globals\",\"isSmall\"]],[32,0,[\"sizeConstraints\"]],[32,0,[\"columns\"]],[32,0,[\"queryManager\"]],[32,0,[\"onItemClick\"]],[30,[36,2],[\"sentry-advisor-messages-filter\"],[[\"site\"],[[32,1]]]],\"list-items/sentry-advisor-message\",true]],null],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"selectedMessage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"dialogs/sentry-advisor-message\",[],[[\"@message\",\"@onClose\"],[[32,0,[\"selectedMessage\"]],[30,[36,1],[[30,[36,0],[[32,0,[\"selectedMessage\"]]],null],null],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"component\",\"if\"]}",
    "moduleName": "sentry-advisor/pods/components/sentry-advisor-messages-view/template.hbs"
  });

  _exports.default = _default;
});