define("sentry-advisor/pods/components/list-items/pump-cycle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+LPrDZbW",
    "block": "{\"symbols\":[\"@item\",\"@advancedMonitorPanel\"],\"statements\":[[8,\"paper-item\",[],[[\"@class\"],[[30,[36,2],[\"pump-cycle-item \",[30,[36,1],[[32,2],\"md-3-line\",\"md-2-line\"],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"md-list-item-text\"],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[2,\"\\n      \"],[1,[32,1,[\"formattedStartedAt\"]]],[2,\" - \"],[1,[32,1,[\"durationTimeString\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"\\n      \"],[1,[32,1,[\"pumpName\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,1],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"wattage\"]],\"-\"],null]],[2,\" W\\n        \"],[1,[30,[36,0],[[32,1,[\"voltage\"]],\"-\"],null]],[2,\" V\\n        \"],[1,[30,[36,0],[[32,1,[\"current\"]],\"-\"],null]],[2,\" C\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"if\",\"concat\"]}",
    "moduleName": "sentry-advisor/pods/components/list-items/pump-cycle/template.hbs"
  });

  _exports.default = _default;
});