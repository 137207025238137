define("htt-ui/components/ui/list-items/rtu-config-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PaperItem @onClick={{@onClick}} @class="md-3-line" data-test-rtu-config-item>
    <div class="md-list-item-text">
      <p><strong>Type</strong>: {{@item.type}}</p>
  
      {{#if @item.formattedDesiredTime}}
        <p><strong>Desired</strong>: {{@item.formattedDesiredTime}}</p>
      {{/if}}
      
      {{#if @item.formattedReportedTime}}
        <p><strong>Reported</strong>: {{@item.formattedReportedTime}}</p>
      {{/if}}
    </div>
  </PaperItem>
  */
  {
    "id": "mxGnYM9p",
    "block": "{\"symbols\":[\"@item\",\"@onClick\"],\"statements\":[[8,\"paper-item\",[[24,\"data-test-rtu-config-item\",\"\"]],[[\"@onClick\",\"@class\"],[[32,2],\"md-3-line\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"md-list-item-text\"],[12],[2,\"\\n    \"],[10,\"p\"],[12],[10,\"strong\"],[12],[2,\"Type\"],[13],[2,\": \"],[1,[32,1,[\"type\"]]],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,1,[\"formattedDesiredTime\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[12],[10,\"strong\"],[12],[2,\"Desired\"],[13],[2,\": \"],[1,[32,1,[\"formattedDesiredTime\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \\n\"],[6,[37,0],[[32,1,[\"formattedReportedTime\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[12],[10,\"strong\"],[12],[2,\"Reported\"],[13],[2,\": \"],[1,[32,1,[\"formattedReportedTime\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "htt-ui/components/ui/list-items/rtu-config-item.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});