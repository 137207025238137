define("sentry-advisor/pods/account-group/users/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KNClvW5X",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"dialogs/user\",[],[[\"@user\",\"@status\",\"@title\",\"@onCancel\",\"@afterCreate\"],[[32,1],\"create\",[30,[36,0],[\"newItem\"],[[\"item\"],[[30,[36,0],[\"user.one\"],null]]]],[30,[36,1],[\"account-group.users\"],null],[32,0,[\"afterCreate\"]]]],null]],\"hasEval\":false,\"upvars\":[\"t\",\"transition-to\"]}",
    "moduleName": "sentry-advisor/pods/account-group/users/new/template.hbs"
  });

  _exports.default = _default;
});