define("sentry-advisor/pods/components/sites-map/layer-control/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rq+zGRCQ",
    "block": "{\"symbols\":[\"buttonOption\",\"index\",\"@buttonOptions\"],\"statements\":[[10,\"div\"],[14,0,\"mdl-map__layer_control_container\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[32,1,[\"active\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"paper-button\",[],[[\"@raised\",\"@primary\",\"@onClick\"],[true,true,[30,[36,0],[[32,0],\"buttonClicked\",[32,2]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[35,1,[\"isSmall\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[1,[32,1,[\"abbr\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[1,[32,1,[\"text\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"globals\",\"if\",\"unless\",\"-track-array\",\"each\"]}",
    "moduleName": "sentry-advisor/pods/components/sites-map/layer-control/template.hbs"
  });

  _exports.default = _default;
});