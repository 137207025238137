define("sentry-advisor/pods/components/sentry-advisor-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "leGeSENl",
    "block": "{\"symbols\":[\"@site\"],\"statements\":[[10,\"div\"],[14,0,\"info-card layout-column\"],[12],[2,\"\\n    \"],[6,[37,1],[[32,1,[\"hasNocommAlarm\"]]],null,[[\"default\"],[{\"statements\":[[8,\"paper-item\",[],[[\"@class\"],[\"sa-in-alarm-color\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"comm-error\"],null]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"layout-column md-padding\"],[12],[2,\"\\n        \"],[10,\"span\"],[12],[1,[30,[36,1],[[32,1,[\"sentryAdvisor\"]],[32,1,[\"sentryAdvisor\",\"label\"]],[30,[36,0],[\"no-item\"],[[\"item\"],[[30,[36,0],[\"modem\"],null]]]]],null]],[13],[2,\"\\n\"],[6,[37,1],[[32,1,[\"sentryAdvisor\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[12],[1,[32,1,[\"sentryAdvisor\",\"sentryAdvisorModel\",\"serviceProvider\"]]],[13],[2,\"\\n        \"],[10,\"span\"],[12],[1,[30,[36,0],[\"signal-strength\"],null]],[2,\":\\n            \"],[8,\"material-device-icon\",[],[[\"@iconName\"],[[32,0,[\"signalIconString\"]]]],null],[2,\"\\n            \"],[1,[32,1,[\"reportedSignalStrength\"]]],[2,\"dB\"],[13],[2,\"\\n        \"],[10,\"span\"],[12],[1,[30,[36,0],[\"last-comm\"],null]],[2,\": \"],[1,[32,0,[\"lastReportedAt\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "moduleName": "sentry-advisor/pods/components/sentry-advisor-status/template.hbs"
  });

  _exports.default = _default;
});