define("ember-leaflet/helpers/ember-leaflet-or", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emberLeafletOr = emberLeafletOr;
  _exports.default = void 0;

  function emberLeafletOr(params) {
    return params.reduce(function (previous, current) {
      return previous || current;
    }, undefined);
  }

  var _default = Ember.Helper.helper(emberLeafletOr);

  _exports.default = _default;
});