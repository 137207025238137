define("sentry-advisor/pods/components/app-bars/user-manual-menu-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S4lRO8H4",
    "block": "{\"symbols\":[\"content\",\"@content\"],\"statements\":[[6,[37,1],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,[32,1,[\"menu-item\"]],[[24,\"data-test-app-bars-user-manual-menu-item\",\"\"]],[[\"@href\"],[[32,0,[\"globals\",\"eoneUserManualLink\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      description\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"user-manual\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"with\"]}",
    "moduleName": "sentry-advisor/pods/components/app-bars/user-manual-menu-item/template.hbs"
  });

  _exports.default = _default;
});