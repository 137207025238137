define("sentry-advisor/pods/account/notes/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qAyNQkT/",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"dialogs/service-log-form\",[],[[\"@log\",\"@hideInstallationSelect\",\"@onSave\",\"@onCancel\"],[[32,1],true,[30,[36,0],[\"account.notes\"],null],[30,[36,0],[\"account.notes\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"transition-to\"]}",
    "moduleName": "sentry-advisor/pods/account/notes/new/template.hbs"
  });

  _exports.default = _default;
});