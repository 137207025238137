define("sentry-advisor/pods/account/notification-settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NJT51RkK",
    "block": "{\"symbols\":[\"tabs\",\"groupRoute\"],\"statements\":[[8,\"app-bars/account\",[],[[\"@account\",\"@title\"],[[32,0,[\"account\"]],[30,[36,0],[\"notification-settings\"],null]]],null],[2,\"\\n  \"],[8,\"paper-tabs\",[],[[\"@selected\",\"@borderBottom\",\"@onChange\"],[[32,0,[\"router\",\"currentURL\"]],true,[32,0,[\"noop\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"siteGroupRoutes\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,1,[\"tab\"]],[],[[\"@value\",\"@href\"],[[32,2,[\"url\"]],[32,2,[\"url\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,2,[\"siteGroup\",\"eoneLabel\"]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[1,[30,[36,4],[[30,[36,3],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\",\"-outlet\",\"component\"]}",
    "moduleName": "sentry-advisor/pods/account/notification-settings/template.hbs"
  });

  _exports.default = _default;
});