define("sentry-advisor/pods/account-group/sentry-advisors/upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "puEayGgF",
    "block": "{\"symbols\":[],\"statements\":[[8,\"dialogs/bulk-sentry-advisor-create\",[],[[\"@onClose\"],[[30,[36,0],[\"account-group.sentry-advisors\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"transition-to\"]}",
    "moduleName": "sentry-advisor/pods/account-group/sentry-advisors/upload/template.hbs"
  });

  _exports.default = _default;
});