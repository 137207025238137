define("sentry-advisor/pods/components/charts/chart-wrapper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9WpW9wrI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,4],null,[[\"header\",\"content\",\"footer\"],[[30,[36,2],[\"charts/chart-wrapper/selectors\"],[[\"hideTable\",\"toggleTable\"],[[30,[36,1],[[35,0]],null],[30,[36,3],[[32,0],\"toggleTable\"],null]]]],[30,[36,2],[\"charts/chart-wrapper/chart\"],null],[30,[36,2],[\"charts/chart-wrapper/table\"],[[\"hideTable\"],[[30,[36,1],[[35,0]],null]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hideTable\",\"readonly\",\"component\",\"action\",\"hash\"]}",
    "moduleName": "sentry-advisor/pods/components/charts/chart-wrapper/template.hbs"
  });

  _exports.default = _default;
});