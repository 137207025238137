define("ember-leaflet/helpers/ember-leaflet-hash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emberLeafletHash = emberLeafletHash;
  _exports.default = void 0;

  function emberLeafletHash(_, named) {
    return Object.assign({}, named);
  }

  var _default = Ember.Helper.helper(emberLeafletHash);

  _exports.default = _default;
});