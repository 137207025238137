define("sentry-advisor/pods/components/pump-cycles-view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kFp1Dg5T",
    "block": "{\"symbols\":[\"@site\"],\"statements\":[[8,\"query-manager-view\",[],[[\"@listView\",\"@columns\",\"@queryManager\",\"@filterComponent\",\"@listItemComponent\",\"@hideSearch\"],[[32,0,[\"globals\",\"isSmall\"]],[32,0,[\"columns\"]],[32,0,[\"queryManager\"]],[30,[36,0],[\"query-manager-view-filters/pump-cycle\"],[[\"installation\"],[[32,1]]]],[30,[36,0],[\"list-items/pump-cycle\"],[[\"advancedMonitorPanel\"],[[32,0,[\"advancedMonitorPanel\"]]]]],true]],null]],\"hasEval\":false,\"upvars\":[\"component\"]}",
    "moduleName": "sentry-advisor/pods/components/pump-cycles-view/template.hbs"
  });

  _exports.default = _default;
});