define("sentry-advisor/pods/account-group/sites/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GTrKzUOZ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"showToolbar\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"app-bars/account-group\",[],[[\"@title\"],[[30,[36,0],[\"sites\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"-outlet\",\"component\"]}",
    "moduleName": "sentry-advisor/pods/account-group/sites/template.hbs"
  });

  _exports.default = _default;
});