define("sentry-advisor/pods/account/notes/note/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T2BE5nfg",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"dialogs/service-log-form\",[],[[\"@log\",\"@noServicer\",\"@hideInstallationSelect\",\"@onSave\",\"@onCancel\"],[[32,1],true,true,[30,[36,0],[\"account.notes.note.index\"],null],[30,[36,0],[\"account.notes.note.index\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"transition-to\"]}",
    "moduleName": "sentry-advisor/pods/account/notes/note/edit/template.hbs"
  });

  _exports.default = _default;
});