define("htt-ui/components/ui/buttons/icon-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PaperButton @iconButton={{true}} @onClick={{@onClick}} ...attributes>
    <PaperIcon>
      {{@icon}}
    </PaperIcon>
  </PaperButton>
  */
  {
    "id": "4pL2jHKn",
    "block": "{\"symbols\":[\"@onClick\",\"&attrs\",\"@icon\"],\"statements\":[[8,\"paper-button\",[[17,2]],[[\"@iconButton\",\"@onClick\"],[true,[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,3]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "htt-ui/components/ui/buttons/icon-button.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});