define("sentry-advisor/pods/components/charts/chart-wrapper/table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ChPfL1kL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[18,1,[[30,[36,2],null,[[\"table\"],[[30,[36,1],[[35,0]],[[\"class\"],[\"flex-40 layout-column flex-grow\"]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"tableName\",\"component\",\"hash\",\"hideTable\",\"unless\"]}",
    "moduleName": "sentry-advisor/pods/components/charts/chart-wrapper/table/template.hbs"
  });

  _exports.default = _default;
});