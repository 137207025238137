define("sentry-advisor/pods/components/site-battery-power-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mQJQItWl",
    "block": "{\"symbols\":[\"@site\"],\"statements\":[[8,\"paper-item\",[],[[\"@class\"],[[31,[[30,[36,0],[[32,1,[\"reportedBatteryPowerStatus\"]],\"sa-not-in-alarm-color\",\"sa-in-alarm-color\"],null]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[12],[1,[30,[36,1],[\"battery-power\"],null]],[2,\" \"],[1,[32,0,[\"statusText\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"t\"]}",
    "moduleName": "sentry-advisor/pods/components/site-battery-power-status/template.hbs"
  });

  _exports.default = _default;
});