define("sentry-advisor/pods/components/charts/chart-wrapper/selectors/show-hide-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EfmI2+dP",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],null,[[\"class\",\"noInk\",\"bubbles\",\"iconButton\",\"primary\",\"onClick\"],[\"\",true,false,true,true,[35,4]]],[[\"default\"],[{\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"grid_on\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[6,[37,3],null,[[\"position\"],[\"left\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,2],[\"TOGGLE_THING\"],[[\"toggle\",\"thing\"],[[30,[36,1],[[35,0],\"show\",\"hide\"],null],\"table\"]]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"grid_off\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[6,[37,3],null,[[\"position\"],[\"left\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,2],[\"TOGGLE_THING\"],[[\"toggle\",\"thing\"],[[30,[36,1],[[35,0],\"show\",\"hide\"],null],\"table\"]]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hideTable\",\"if\",\"t\",\"paper-tooltip\",\"toggleTable\",\"paper-button\"]}",
    "moduleName": "sentry-advisor/pods/components/charts/chart-wrapper/selectors/show-hide-table/template.hbs"
  });

  _exports.default = _default;
});