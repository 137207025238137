define("sentry-advisor/pods/components/alarms-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M0sT2XZS",
    "block": "{\"symbols\":[\"alarm\"],\"statements\":[[8,\"query-manager-list\",[],[[\"@queryManager\",\"@listModelsLabel\"],[[32,0,[\"queryManager\"]],[30,[36,2],[\"alarms\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"alarm-list-item\",[],[[\"@alarm\",\"@compressed\",\"@onClick\",\"@showLocation\"],[[32,1],[30,[36,4],[[35,3,[\"isLarge\"]]],null],[30,[36,1],[[32,0],\"alarmClicked\",[32,1]],null],[32,0,[\"showSite\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[6,[37,5],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"dialogs/alarm\",[],[[\"@alarm\",\"@onClose\",\"@showSite\",\"@showCustomer\"],[[34,0],[30,[36,1],[[32,0],\"unselectAlarm\"],null],[32,0,[\"showSite\"]],[32,0,[\"showCustomer\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"selectedAlarm\",\"action\",\"t\",\"globals\",\"not\",\"if\"]}",
    "moduleName": "sentry-advisor/pods/components/alarms-list/template.hbs"
  });

  _exports.default = _default;
});