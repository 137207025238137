define("sentry-advisor/pods/components/sentry-advisor-messages-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lXi1aEPn",
    "block": "{\"symbols\":[\"@site\"],\"statements\":[[10,\"div\"],[14,0,\"layout-column\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"md-padding border-bottom\"],[14,\"data-test-sentry-advisor-message-filter-body\",\"\"],[12],[2,\"\\n    \"],[1,[30,[36,1],null,[[\"allowClear\",\"class\",\"label\",\"date\",\"maxDate\",\"onChange\"],[true,\"flex-grow\",[30,[36,0],[\"startDate\"],null],[32,0,[\"startDate\"]],[32,0,[\"endDate\"]],[32,0,[\"updateStartDate\"]]]]]],[2,\"\\n    \"],[1,[30,[36,1],null,[[\"allowClear\",\"class\",\"label\",\"date\",\"minDate\",\"onChange\"],[true,\"flex-grow\",[30,[36,0],[\"endDate\"],null],[32,0,[\"endDate\"]],[32,0,[\"startDate\"]],[32,0,[\"updateEndDate\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"layout-row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex-grow\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex-noshrink\"],[12],[2,\"\\n      \"],[8,\"reports/sentry-advisor-message-list-button\",[],[[\"@site\"],[[32,1]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"ui/inputs/datepicker\"]}",
    "moduleName": "sentry-advisor/pods/components/sentry-advisor-messages-filter/template.hbs"
  });

  _exports.default = _default;
});