define("sentry-advisor/pods/account-group/alarms/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AH3zxwtn",
    "block": "{\"symbols\":[],\"statements\":[[8,\"app-bars/account-group\",[],[[\"@title\"],[[30,[36,0],[\"alarms\"],null]]],null],[2,\"\\n\"],[8,\"alarms-view\",[],[[\"@accountGroup\",\"@showCustomer\"],[[32,0,[\"model\"]],true]],null]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "sentry-advisor/pods/account-group/alarms/template.hbs"
  });

  _exports.default = _default;
});