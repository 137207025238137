define("sentry-advisor/pods/components/site-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ivyqvnMh",
    "block": "{\"symbols\":[\"pump\",\"@site\"],\"statements\":[[8,\"paper-content\",[[24,0,\"page-content\"]],[[\"@padding\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[2,\"  \"],[10,\"div\"],[14,0,\"layout-row layout-wrap\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2,[\"sentryAdvisorSite\",\"pumps\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"flex-100 flex-gt-sm-50\"],[14,5,\"padding: 5px;\"],[12],[2,\"\\n      \"],[8,\"site-pump-status\",[],[[\"@pump\",\"@site\"],[[32,1],[32,2]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[8,\"paper-subheader\",[],[[],[]],[[\"default\"],[{\"statements\":[[1,[30,[36,2],[\"status\"],null]]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"layout-column\"],[14,5,\"padding: 5px;\"],[12],[2,\"\\n    \"],[8,\"site-alarm-status\",[],[[\"@site\"],[[32,2]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"paper-subheader\",[],[[],[]],[[\"default\"],[{\"statements\":[[1,[30,[36,2],[\"sentry-advisor\"],null]]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"layout-column\"],[14,5,\"padding: 5px;\"],[12],[2,\"\\n    \"],[8,\"sentry-advisor-status\",[],[[\"@site\"],[[32,2]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"t\"]}",
    "moduleName": "sentry-advisor/pods/components/site-status/template.hbs"
  });

  _exports.default = _default;
});